/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Notification } from 'rsuite';
import { DataItem } from '../../../../../@types/DataItem';
import {
    useFilterData,
    useFilterSearch,
    useFilterValue,
} from '../../../../../components';
import {
    listProducts,
    listSegment,
    listStores,
} from '../../../../../components/FilterSection/services';
import { useCount } from '../../../../../hooks';
import {
    Segment,
    getCattegories,
} from '../../../../../services/ProdutoService_ts';
import { checkIfIsLoading } from '../../../../../utils';
import History from '../../../../../utils/History';
import { saveRegra } from '../../../RegrasPreco/Cadastro/services';
import {
    SchemaNewRuleProps,
    SegmentsCurve,
    createSchemaNewRule,
    defaultFormValues,
    getNewRuleModel,
    parseEnumeratedSegments,
} from './lib';
import DATA, { StoreType } from './lib/data';

type Keys = Segment['field'] | SegmentsCurve | 'storeIds';

const INITIAL_ROWS = 1;

const SchemaNewRule = createSchemaNewRule();

export const useRegrasDePreco = () => {
    const form = useForm<SchemaNewRuleProps>({
        defaultValues: defaultFormValues,
        resolver: zodResolver(SchemaNewRule),
    });

    const [rows, { increment, decrement }] = useCount({
        initialData: INITIAL_ROWS,
    });

    const [selectedCattegories, setSelectedCattegories] = React.useState<
        Array<Keys>
    >([]);

    const [data, { set: setData }] = useFilterData<Keys>();

    const [
        cache,
        { set: setCache, resetByKey: resetCacheByKey, reset: resetCache },
    ] = useFilterData<Keys>();

    const [
        value,
        { set: setValue, resetByKey: resetValueByKey, reset: resetValue },
    ] = useFilterValue<Keys>();

    const [search, { onSearch: handleSearch }] = useFilterSearch<Keys>();

    const [filterType, setFilterType] = React.useState<{
        storeIds?: StoreType;
        segment?: SegmentsCurve;
    }>({
        storeIds: 'all-stores',
    });

    const { data: segments } = useQuery({
        queryKey: ['filters.cattegory.type'],
        queryFn: async (): Promise<Segment[]> => {
            const segments = await getCattegories();
            const numeratedSegments = parseEnumeratedSegments(segments);
            return [
                ...numeratedSegments,
                {
                    level: 0,
                    field: 'productIds',
                    value: 'productIds',
                    name: 'Produto',
                    label: 'Produto',
                },
            ];
        },
        initialData: [],
        retry: false,
    });

    const onSuccess = (key: Keys, data: DataItem[]) => {
        const uniqueData = _.uniqBy(
            [...(cache[key] ?? []), ...(data ?? [])],
            'value',
        );
        setData(key, uniqueData);
    };

    const results = useQueries({
        queries: segments.map(({ value: key, level }) => ({
            queryKey: ['segment-data', key, search[key]],
            retry: false,
            queryFn: () => {
                return key === 'productIds'
                    ? listProducts(search[key])
                    : listSegment(search[key], level);
            },
            onSuccess: (data: DataItem[]) => onSuccess(key, data),
            enabled: !!selectedCattegories.includes(key),
        })),
    });

    const alreadySelectedSegmentsData = segments.filter((segment) => {
        return selectedCattegories.includes(segment.value);
    });

    const avaliableCattegories = segments.filter((segment) => {
        return !selectedCattegories.includes(segment.value);
    });

    const isLastRow = rows === segments.length;

    const handleChangeCattegoryType = React.useCallback(
        (key: Keys, index: number) => {
            setSelectedCattegories((oldValue) => {
                if (value[oldValue[index]]?.length && oldValue[index] !== key) {
                    resetValueByKey(oldValue[index]);
                    resetCacheByKey(oldValue[index]);
                }
                const newValue = [...oldValue];
                newValue[index] = key;
                return _.uniq(newValue);
            });
        },
        [resetValueByKey, resetCacheByKey],
    );

    const handleSelect = React.useCallback(
        (key: Keys, value: string[]) => {
            const cache = data[key]?.filter((item) => {
                return value.includes(item.value);
            });
            setValue(key, value);
            setCache(key, cache ?? []);
        },
        [data, setValue, setCache],
    );

    const handleAddRow = React.useCallback(() => {
        if (isLastRow) return;
        increment();
    }, [isLastRow, increment]);

    const handleRemoveRow = React.useCallback(
        (index: number) => {
            if (rows === INITIAL_ROWS) return;
            const key = selectedCattegories[index];
            setSelectedCattegories((oldValue) => {
                const newValue = [...oldValue];
                newValue.splice(index, 1);
                return newValue;
            });
            resetValueByKey(key);
            resetCacheByKey(key);
            decrement();
        },
        [
            rows,
            selectedCattegories,
            resetValueByKey,
            resetCacheByKey,
            decrement,
        ],
    );

    const handleClean = React.useCallback(
        (key: Keys, index: number) => {
            if (value[key]?.length) {
                resetValueByKey(key);
                resetCacheByKey(key);
            }

            setSelectedCattegories((oldValue) => {
                const newValue = [...oldValue];
                newValue.splice(index, 1);
                return newValue;
            });
        },
        [value],
    );

    const getSegmentsModel = React.useCallback(() => {
        type SegmentsModel = {
            value: string;
            level: 'productId' | Keys;
        } & Record<string, unknown>;

        const Segments = new Set<SegmentsModel>([]);

        Object.entries(value).forEach(([key, value]) => {
            const _key = key as Keys;

            if (_key === 'productIds') {
                cache.productIds?.forEach((item) => {
                    Segments.add({
                        level: 'productId',
                        value: item.value,
                        description: item.description,
                    });
                });
            }

            if (_key.match(/categoryLevel/)) {
                value.forEach((item) => {
                    Segments.add({
                        level: _key,
                        value: item,
                    });
                });
            }
        });
        return Array.from(Segments);
    }, [value, cache]);

    const onSubmit = React.useCallback(
        async (formData: SchemaNewRuleProps) => {
            const result = SchemaNewRule.safeParse(formData);

            if (!result.success) {
                throw new Error('Dados inválidos');
            }

            const model = {
                ...getNewRuleModel(result.data),
                segments: getSegmentsModel(),
                storeIds:
                    filterType.storeIds === 'individual-store'
                        ? value.storeIds
                        : [],
                ..._.pick(value, [
                    'infoPriceSensibility',
                    'infoPriceAbcClass',
                    'infoPriceGlobalAbcClass',
                    'sensibilityType',
                    'abcClass',
                ]),
            };

            const res = await saveRegra(model);

            if ([200, 201].includes(res.status)) {
                History.push('/ipa/regras-preco');
                resetValue();
                resetCache();
            } else {
                Notification.error({
                    title: 'Notificação',
                    duration: 6000,
                    description:
                        'Erro ao criar Regra, favor entrar em contato com o administrador.',
                });
            }
        },
        [
            filterType,
            value,
            data,
            getNewRuleModel,
            getSegmentsModel,
            saveRegra,
            resetValue,
            resetCache,
        ],
    );

    const handleSelectFilterType = React.useCallback(
        (key: keyof typeof filterType, value: string) => {
            setFilterType((oldValue) => {
                const isTheSameValue = oldValue[key] === value;

                if (oldValue[key] && !isTheSameValue) {
                    resetValueByKey(oldValue[key] as Keys);
                    resetCacheByKey(oldValue[key] as Keys);
                }

                return {
                    ...oldValue,
                    [key]: value,
                };
            });
        },
        [resetValueByKey, resetCacheByKey, setFilterType],
    );

    const cleanFilterType = React.useCallback(
        (key: keyof typeof filterType) => {
            setFilterType((oldValue) => {
                const newValue = { ...oldValue };
                delete newValue[key];
                return newValue;
            });
        },
        [setFilterType],
    );

    const { data: stores, fetchStatus: storeLoading } = useQuery({
        queryKey: ['storeIds', search?.storeIds],
        initialData: [],
        retry: false,
        queryFn: () => listStores(search?.storeIds),
        onSuccess: (data: DataItem[]) => onSuccess('storeIds', data),
    });

    const isSegmentEmpty = (() => {
        for (const key in value) {
            if (
                (key.match(/categoryLevel/) || key.match(/productIds/)) &&
                value[key as Keys]?.length
            ) {
                return false;
            }
        }
        return true;
    })();

    const isStoreEmpty =
        filterType.storeIds === 'individual-store'
            ? !value.storeIds?.length
            : false;

    const isFilterValid = !isSegmentEmpty && !isStoreEmpty;

    return {
        form,
        onSubmit,
        filters: {
            isSegmentEmpty,
            isFilterValid,
            isStoreEmpty,
            cattegory: {
                rows,
                search,
                data,
                value,
                isLastRow,
                selectedCattegories,
                alreadySelectedSegmentsData,
                avaliableCattegories,
                handleClean,
                handleSearch,
                handleChangeCattegoryType,
                handleSelectCattegory: handleSelect,
                handleAddRow,
                handleRemoveRow,
                results,
            },
            store: {
                select: {
                    data: DATA.storeType,
                    value: filterType.storeIds,
                    onSelect(value: StoreType) {
                        if (value === 'all-stores') {
                            resetValueByKey('storeIds');
                            resetCacheByKey('storeIds');
                        }
                        handleSelectFilterType('storeIds', value);
                    },
                    onClean() {
                        cleanFilterType('storeIds');
                        resetValueByKey('storeIds');
                        resetCacheByKey('storeIds');
                    },
                },
                check: {
                    data: stores ?? [],
                    value: value.storeIds ?? [],
                    onSearch(query: string) {
                        handleSearch('storeIds', query);
                    },
                    onSelect(value: string[]) {
                        handleSelect('storeIds', value);
                    },
                    onClean() {
                        resetValueByKey('storeIds');
                        resetCacheByKey('storeIds');
                    },
                    onClose() {
                        if (!search.storeIds?.length) return;
                        handleSearch('storeIds', '');
                    },
                    isLoading: checkIfIsLoading(storeLoading),
                },
            },
            segment: {
                select: {
                    data: DATA.segmentsType,
                    value: filterType?.segment,
                    onSelect(segment: SegmentsCurve) {
                        handleSelectFilterType('segment', segment);
                    },
                    onClean() {
                        if (filterType.segment) {
                            resetValueByKey(filterType.segment);
                            resetCacheByKey(filterType.segment);
                        }
                        setFilterType((oldValue) => {
                            const newValue = { ...oldValue };
                            delete newValue.segment;
                            return newValue;
                        });
                    },
                },
                check: {
                    value: filterType.segment ? value[filterType.segment] : [],
                    onSelect(key: SegmentsCurve, value: string[]) {
                        setValue(key, value);
                        setCache(
                            key,
                            data[key]?.filter((item) => {
                                return value.includes(item.value);
                            }) ?? [],
                        );
                    },
                    onClean(key: SegmentsCurve) {
                        resetValueByKey(key);
                        resetCacheByKey(key);
                    },
                },
            },
        },
    } as const;
};
