import React from 'react';
import { Button, Modal } from 'rsuite';
import { BigNumbersBox } from '../BigNumbersBox';
import Badge from '../../../../../../components/Badge';
import styles from './styles.module.scss';

export interface IModalOnDemandPlan {
    onCancel():void;
    onConfirm():void;
    show: boolean;
    requestSummaryData: any;
}

export const ModalOnDemandPlan = ({
    show,
    onCancel,
    onConfirm,
    requestSummaryData,
}: IModalOnDemandPlan) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className={styles['plan-container']}
        onHide={onCancel}
    >
        <Modal.Header closeButton onHide={onCancel}>
            <div className={styles['header-title']}>
                <h1>
                    Veja o seu plano On Demand
                </h1>
                <Badge
                    label={requestSummaryData?.recorrencia === 'SEMANAL' ? 'semanal' : 'diário'}
                    className="pill-primary"
                />
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className={styles['plan-content']}>
                <div className={styles['box-Wrapper']}>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>
                            {requestSummaryData?.requestsPlan === null ? 0 : requestSummaryData?.requestsPlan}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>
                            Solicitações
                            {' '}
                            <strong>
                                {requestSummaryData?.recorrencia === 'SEMANAL' ? 'semanais' : 'diárias'}
                            </strong>
                        </BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>inclusas no plano</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root marginRight="32px">
                        <BigNumbersBox.MainValue>
                            {requestSummaryData?.requestsAdditionalPlan === null ? 0 : requestSummaryData?.requestsAdditionalPlan}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações adicionais</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>ao plano</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root>
                        <BigNumbersBox.MainValue>
                            {requestSummaryData ? (requestSummaryData?.totalRequests - requestSummaryData?.available) : 0}
                        </BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>em uso</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                    <BigNumbersBox.Root type="available">
                        <BigNumbersBox.MainValue>{requestSummaryData?.available}</BigNumbersBox.MainValue>
                        <BigNumbersBox.RegularText>Solicitações</BigNumbersBox.RegularText>
                        <BigNumbersBox.RegularText>disponíveis</BigNumbersBox.RegularText>
                    </BigNumbersBox.Root>
                </div>
                <div className={styles['text-Wrapper']}>
                    {requestSummaryData?.recorrencia === 'SEMANAL' ? (
                        <p>
                            Suas solicitações são <strong> processadas semanalmente</strong>.
                            Você pode aumentar a frequência das buscas optando pelo<strong> plano diário. </strong>
                            A quantidade solicitações adicionais ao seu contrato pode aumentar conforme a sua necessidade.
                        </p>
                    ) : (
                        <p>
                            Suas solicitações são <strong> processadas diariamente</strong>.
                            A quantidade <strong>solicitações adicionais</strong> ao seu contrato pode aumentar conforme a sua necessidade.
                        </p>
                    )}
                    <p className={styles['bottom-text']}>
                        Entre em contato via CSM e converse sobre a compra e/ou negociação de mais solicitações.
                        Você pode fazer isso por e-mail ou clicar no botão "Quero mais solicitações" abaixo.
                    </p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost">
                Fechar
            </Button>
            <Button onClick={onConfirm} appearance="primary">
                Quero mais solicitações
            </Button>
        </Modal.Footer>
    </Modal>
);
