/* eslint-disable react/sort-comp */
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    ButtonToolbar,
    Col,
    Content,
    FlexboxGrid,
    HelpBlock,
    Icon,
    IconButton,
    Radio,
    RadioGroup,
    SelectPicker,
    Toggle,
    Tooltip,
    Whisper,
} from 'rsuite';

import {
    deleteFiltro as deleteFiltroService,
    getFiltrosSalvos,
} from '../services';

import MobileDatePickerComponent from '../../../../../components/MobileDatePickerComponent';
import MobileSingleSelectComponent from '../../../../../components/MobileSingleSelectComponent';
import ModalConfirmation from '../../../../../components/ModalConfirmation';
import MobileMoreFiltersComponent from './MobileMoreFilters';
import Speaker from './SpeakerCustomFilter';

import {
    addFiltro,
    changeNoCacheValue,
    clearFiltrosPanel,
    clearTotalDatapoints,
    deleteFiltro,
    deleteFiltroEndereco,
    searchProdutos,
    selectValueNoCache,
    setAd,
    setChannel,
    setDataPanel,
    setErro,
    setFiltrosSalvosPanel,
    setLatLon,
    setLimitProducts,
    setPriceFilter,
} from '../../../../../actions/actionsPainelGeral';

import CustomFilter from './CustomFilter';
import ModalSalvarFiltro from './ModalSalvarFiltro';
import ProductFilter from './ProductFilter';

import infoIcon from '../../../../../assets/icons/icon_info_gray.svg';
import shoppingBrasil from '../../../../../assets/shoppingBrasil/powered_by_shopping_brasil.svg';
import DataRangePicker from '../../../../../components/DataRangePicker';
import { dateLocale, getModelPanel } from '../utils';

const dateShortcut = [
    {
        label: 'Últimos 7 dias',
        value: [moment().subtract(7, 'days').toDate(), moment().toDate()],
    },
    {
        label: 'Últimos 30 dias',
        value: [moment().subtract(30, 'days').toDate(), moment().toDate()],
    },
    {
        label: 'Últimos 90 dias',
        value: [moment().subtract(90, 'days').toDate(), moment().toDate()],
    },
];

const filterClearList = [
    'rede',
    'tipoLoja',
    'fabricante',
    'tipoProduto',
    'secao',
    'categoria',
    'mecanica',
    'grupoMidia',
    'midia',
    'uf',
    'cidade',
    'endereco',
    'tipoPreco',
    'origemPreco',
    'raio',
    'produto',
];

class FiltersArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModalSalvarFiltro: false,
            channelSellingPointToggle: true,
            channelAppToggle: true,
            priceFilter: 'PRECO_PAGO',
            showModalConfirm: false,
        };

        this.selectFiltroSalvo = this.selectFiltroSalvo.bind(this);
        this.changeProductsDetails = this.changeProductsDetails.bind(this);
        this.setFiltrosFromFiltroSalvo =
            this.setFiltrosFromFiltroSalvo.bind(this);
        this.deletarFiltroSalvo = this.deletarFiltroSalvo.bind(this);
        this.handleChangeFiltroSalvo = this.handleChangeFiltroSalvo.bind(this);
        this.openModalSalvarFiltro = this.openModalSalvarFiltro.bind(this);
        this.closeModalSalvarFiltro = this.closeModalSalvarFiltro.bind(this);
        this.handleFilterData = this.handleFilterData.bind(this);
        this.handleDeleteFiltro = this.handleDeleteFiltro.bind(this);
        this.handleChannelToggle = this.handleChannelToggle.bind(this);
        this.handlePriceFilter = this.handlePriceFilter.bind(this);
        this.resetChannel = this.resetChannel.bind(this);
        this.resetPriceType = this.resetPriceType.bind(this);
        this.handleAdToggle = this.handleAdToggle.bind(this);
        this.handleShoppingBrasilSite =
            this.handleShoppingBrasilSite.bind(this);
        this.handleClearFiltroSalvo = this.handleClearFiltroSalvo.bind(this);
        this.handleOpenModalDeleteFiltroSalvo =
            this.handleOpenModalDeleteFiltroSalvo.bind(this);
    }

    componentDidMount() {
        const { clearFiltrosPanel, clearTotalDatapoints } = this.props;
        clearTotalDatapoints();
        clearFiltrosPanel(filterClearList);
        this.searchFiltrosSalvos();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { errors, filtrosAdicionados, usuario, ad } = this.props;
        const {
            listaFiltrosSalvos,
            showModalSalvarFiltro,
            channelSellingPointToggle,
            channelAppToggle,
            showModalConfirm,
            filtroSalvo,
            priceFilter,
        } = this.state;
        return (
            nextProps.filtrosAdicionados !== filtrosAdicionados ||
            nextProps.errors !== errors ||
            nextProps.ad !== ad ||
            nextProps.usuario !== usuario ||
            nextState.showModalSalvarFiltro !== showModalSalvarFiltro ||
            nextState.listaFiltrosSalvos !== listaFiltrosSalvos ||
            nextState.channelAppToggle !== channelAppToggle ||
            nextState.channelSellingPointToggle !== channelSellingPointToggle ||
            nextState.showModalConfirm !== showModalConfirm ||
            nextState.filtroSalvo !== filtroSalvo ||
            nextState.priceFilter !== priceFilter
        );
    }

    handlePriceFilter(value) {
        const { setPriceFilter } = this.props;

        this.setState({
            priceFilter: value,
        });

        if (value) {
            setPriceFilter(value);
        }
    }

    handleChangeFiltroSalvo(value) {
        this.setState({
            filtroSalvo: value,
        });

        if (value) {
            this.setFiltrosFromFiltroSalvo(value);
        }
    }

    handleFilterData(value) {
        const { setDataPanel, validInput } = this.props;

        setDataPanel(value);
        setTimeout(() => {
            validInput();
        }, 100);
    }

    handleDeleteFiltro(index, name) {
        const { deleteFiltroEndereco, deleteFiltro } = this.props;

        if (name === 'endereco') {
            deleteFiltroEndereco(index, name);
        } else {
            deleteFiltro(index, name);
        }
    }

    resetChannel(channel) {
        const { setChannel } = this.props;

        if (channel?.length) {
            setChannel(channel);
            this.setState({
                channelAppToggle: channel.includes('Canal digital'),
                channelSellingPointToggle: channel.includes('Canal físico'),
            });
        } else {
            this.setState({
                channelAppToggle: true,
                channelSellingPointToggle: true,
            });
            setChannel(['Canal físico', 'Canal digital']);
        }
    }

    resetPriceType() {
        this.handlePriceFilter('PRECO_PAGO');
    }

    async setFiltrosFromFiltroSalvo(filtro) {
        const {
            usuario,
            searchProdutos,
            addFiltro,
            selectValueNoCache,
            setLatLon,
            filtros,
        } = this.props;

        if (filtro.filters) {
            if (filtro.filters.gtin) {
                const identificadores = filtro.filters.gtin;
                await searchProdutos(
                    getModelPanel(this.props, '', 'produto', identificadores),
                );
            }

            if (filtro.filters.categoria) {
                const value = filtro.filters.categoria;
                this.preencheFiltroSalvo('categoria', value);
            }
            if (filtro.filters.cidade) {
                const value = filtro.filters.cidade;
                this.preencheFiltroSalvo('cidade', value);
            }

            if (
                filtro.filters.rede &&
                usuario?.servicoPanel?.pacotePainel?.name !== 'LITE' &&
                usuario?.servicoPanel?.pacotePainel?.name !== 'FREE'
            ) {
                const value = filtro.filters.rede;
                this.preencheFiltroSalvo('rede', value);
            }

            if (filtro.filters.secao) {
                const value = filtro.filters.secao;
                this.preencheFiltroSalvo('secao', value);
            }

            if (filtro.filters.tipoLoja) {
                const value = filtro.filters.tipoLoja;
                this.preencheFiltroSalvo('tipoLoja', value);
            }

            if (filtro.filters.tipoPreco) {
                const value = filtro.filters.tipoPreco;
                this.preencheFiltroSalvo('tipoPreco', value);
            }

            if (filtro.filters.uf) {
                const value = filtro.filters.uf;
                this.preencheFiltroSalvo('uf', value);
            }

            if (filtro.filters.origemPreco) {
                const value = filtro.filters.origemPreco;
                this.preencheFiltroSalvo('origemPreco', value);
            }

            if (filtro.filters.fabricante) {
                const value = filtro.filters.fabricante;
                this.preencheFiltroSalvo('fabricante', value);
            }

            if (filtro.filters.endereco) {
                const value = {
                    geometry: {
                        location: {
                            lat: filtro.filters.latitude,
                            lng: filtro.filters.longitude,
                        },
                    },
                };
                addFiltro(
                    filtros[
                        filtros.findIndex((item) => item.name === 'endereco')
                    ],
                );
                selectValueNoCache(filtro.filters.endereco, 'endereco');
                setLatLon(value);
            }

            if (filtro.filters.raio) {
                const value = filtro.filters.raio;
                selectValueNoCache(value, 'raio');
            }

            this.resetChannel(filtro.filters.canal);
            this.resetPriceType();
        }
    }

    async selectFiltroSalvo(filtro) {
        const { clearFiltrosPanel } = this.props;

        await clearFiltrosPanel(filterClearList);
        this.setFiltrosFromFiltroSalvo(filtro);
    }

    searchFiltrosSalvos(search) {
        getFiltrosSalvos(search).then((response) => {
            if (response) {
                this.setState({
                    listaFiltrosSalvos: response.map((item) => ({
                        id: item.id,
                        value: item.id,
                        label: item.name,
                        filters: item.filters,
                    })),
                });
            }
        });
    }

    preencheFiltroSalvo(key, value) {
        const {
            setFiltrosSalvosPanel,
            addFiltro,
            changeNoCacheValue,
            filtros,
        } = this.props;

        value.forEach((item) => {
            setFiltrosSalvosPanel(key, item);
        });

        addFiltro(filtros[filtros.findIndex((item) => item.name === key)]);
        changeNoCacheValue(key, value);
    }

    deletarFiltroSalvo() {
        const { filtroSalvo } = this.state;
        this.setState({
            showModalConfirm: false,
        });

        deleteFiltroService(filtroSalvo).then((response) => {
            if (response.status === 200) {
                this.searchFiltrosSalvos();
                Alert.success('Filtro deletado com sucesso');
                this.setState({
                    filtroSalvo: '',
                });
            }
        }, this);
    }

    handleClearFiltroSalvo() {
        this.setState({
            filtroSalvo: '',
        });
    }

    handleOpenModalDeleteFiltroSalvo() {
        this.setState({
            showModalConfirm: true,
        });
    }

    openModalSalvarFiltro() {
        this.setState({
            showModalSalvarFiltro: true,
        });
    }

    closeModalSalvarFiltro() {
        this.setState(
            {
                showModalSalvarFiltro: false,
            },
            this.searchFiltrosSalvos,
        );
    }

    changeProductsDetails(checked) {
        const { setLimitProducts } = this.props;
        setLimitProducts(checked);
    }

    handleChannelToggle(value, name) {
        const { setChannel } = this.props;

        if (!value) {
            if (name === 'Canal digital') {
                this.setState({
                    channelAppToggle: false,
                    channelSellingPointToggle: true,
                });
                setChannel(['Canal físico']);
            } else {
                this.setState({
                    channelAppToggle: true,
                    channelSellingPointToggle: false,
                });
                setChannel(['Canal digital']);
            }
        }

        if (value) {
            if (name === 'Canal digital') {
                this.setState({ channelAppToggle: true });
            } else {
                this.setState({ channelSellingPointToggle: true });
            }
            setChannel(['Canal físico', 'Canal digital']);
        }
    }

    handleAdToggle(value) {
        const { setAd } = this.props;
        setAd(value);
    }

    handleShoppingBrasilSite() {
        window.open('https://w0.shoppingbrasil.com.br/', '_blank').focus();
    }

    render() {
        const maxDate = moment().toDate();
        const minDate = moment().subtract(91, 'days').toDate();
        const {
            filtrosAdicionados,
            errors,
            usuario,
            getDashBoard,
            ad,
            onOpenCustomFilter,
            onSearchCustomFilter,
            onSearchProduto,
            onOpenProduto,
            onClickLoadMoreProduto,
        } = this.props;
        const {
            filtroSalvo,
            listaFiltrosSalvos,
            showModalConfirm,
            showModalSalvarFiltro,
            channelSellingPointToggle,
            channelAppToggle,
            priceFilter,
        } = this.state;

        return (
            <>
                <Content className="filtros">
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={4}
                            lg={6}
                            md={7}
                            style={{ marginBottom: 0 }}
                            xsHidden
                            smHidden
                        >
                            <DataRangePicker
                                appearance="default"
                                block
                                id="date-filter"
                                maxDate={maxDate}
                                minDate={minDate}
                                format="DD/MM/YYYY"
                                locale={dateLocale}
                                name="data"
                                onChangeValue={this.handleFilterData}
                                placeholder="Data*"
                                ranges={dateShortcut}
                            />
                            <HelpBlock
                                className="filtros__message filtros__message_invalid"
                                name="erroData"
                            >
                                {errors?.data}
                            </HelpBlock>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={4}
                            sm={24}
                            xs={24}
                            style={{ marginBottom: 0 }}
                            lgHidden
                            mdHidden
                        >
                            <MobileDatePickerComponent
                                maxDate={maxDate}
                                minDate={minDate}
                                errors={errors}
                                onChange={this.handleFilterData}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={4}
                            lg={8}
                            md={7}
                            xs={24}
                            sm={24}
                        >
                            <ProductFilter
                                usuario={usuario}
                                onOpenProduto={onOpenProduto}
                                onSearchProduto={onSearchProduto}
                                onClickLoadMore={onClickLoadMoreProduto}
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={6}
                            md={6}
                            sm={24}
                            xs={24}
                        >
                            <RadioGroup
                                className="tipo-oferta"
                                inline
                                name="priceFilter"
                                value={priceFilter}
                                onChange={(value) =>
                                    this.handlePriceFilter(value)
                                }
                            >
                                <Radio id="preco-pago" value="PRECO_PAGO">
                                    Preço pago
                                    <span className="price-type-info-icon">
                                        <Whisper
                                            placement="bottom"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Preço promocionado, quando
                                                    há promoção
                                                </Tooltip>
                                            }
                                        >
                                            <img src={infoIcon} alt="" />
                                        </Whisper>
                                    </span>
                                </Radio>
                                <Radio id="preco-regular" value="PRECO_REGULAR">
                                    Preço regular
                                    <span className="price-type-info-icon">
                                        <Whisper
                                            placement="bottom"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Preço original, sem promoção
                                                </Tooltip>
                                            }
                                        >
                                            <img src={infoIcon} alt="" />
                                        </Whisper>
                                    </span>
                                </Radio>
                            </RadioGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={6}
                            md={7}
                            xsHidden
                            smHidden
                        >
                            <SelectPicker
                                className="inf-picker"
                                id="saved-filter-select"
                                filter
                                placeholder="Filtros salvos"
                                data={listaFiltrosSalvos}
                                onChange={this.handleChangeFiltroSalvo}
                                onSelect={(value, item) =>
                                    this.selectFiltroSalvo(item)
                                }
                                onClean={this.handleClearFiltroSalvo}
                                value={filtroSalvo}
                                block
                                locale={{
                                    noResultsText: 'Nenhum filtro criado',
                                    searchPlaceholder:
                                        'Busque o filtro desejado',
                                }}
                            />
                        </FlexboxGrid.Item>
                        {filtroSalvo ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                xs={3}
                                lg={1}
                                md={1}
                                xsHidden
                                smHidden
                            >
                                <Button
                                    id="delete-saved-filter"
                                    color="red"
                                    onClick={
                                        this.handleOpenModalDeleteFiltroSalvo
                                    }
                                >
                                    <Icon icon="trash2" />
                                </Button>
                            </FlexboxGrid.Item>
                        ) : null}
                        <FlexboxGrid.Item
                            componentClass={Col}
                            sm={24}
                            xs={24}
                            lgHidden
                            mdHidden
                        >
                            <FlexboxGrid.Item componentClass={Col} xs={24}>
                                <MobileSingleSelectComponent
                                    id="saved-filter-select"
                                    placeholder="Filtros salvos"
                                    data={listaFiltrosSalvos}
                                    onChange={this.handleChangeFiltroSalvo}
                                    onSelect={this.selectFiltroSalvo}
                                    value={filtroSalvo}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={5}
                            mdPush={1}
                            xsHidden
                            smHidden
                        >
                            <AddFiltroComponent usuario={usuario} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            sm={24}
                            xs={24}
                            lgHidden
                            mdHidden
                        >
                            <MobileMoreFiltersComponent usuario={usuario} />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={3}
                            md={4}
                            lgPush={2}
                            mdPush={3}
                            xsHidden
                            smHidden
                        >
                            <Button
                                id="save-filter-btn"
                                onClick={() => this.openModalSalvarFiltro()}
                                block
                            >
                                Salvar filtro
                            </Button>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            lgHidden
                            mdHidden
                            componentClass={Col}
                            sm={24}
                            xs={24}
                        >
                            <Button
                                id="visualize-dashboard-btn"
                                appearance="primary"
                                justify="end"
                                block
                                onClick={getDashBoard}
                            >
                                Visualizar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid align="middle">
                        {usuario?.servicoPanel?.pacotePainel?.name ===
                        'LIMIT' ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={8}
                                md={8}
                                sm={24}
                                xs={24}
                            >
                                <div>
                                    <Toggle
                                        id="only-available-products-toggle"
                                        onChange={this.changeProductsDetails}
                                        style={{ marginRight: '5px' }}
                                    />
                                    <span>
                                        Ver somente produtos com detalhes
                                        disponíveis
                                    </span>
                                </div>
                            </FlexboxGrid.Item>
                        ) : null}
                        <FlexboxGrid.Item
                            componentClass={Col}
                            lg={8}
                            md={7}
                            sm={24}
                            xs={24}
                        >
                            <div className="channel-type-container">
                                <span className="toggle-title">CANAL</span>
                                <div className="toggle-container">
                                    <div className="channel-selling-point">
                                        <Toggle
                                            size="sm"
                                            checked={channelSellingPointToggle}
                                            id="channel-selling-point-toggle"
                                            onChange={(e) =>
                                                this.handleChannelToggle(
                                                    e,
                                                    'Canal físico',
                                                )
                                            }
                                            style={{ marginRight: '5px' }}
                                        />
                                        <span>Canal físico</span>
                                        <span className="toggle-info-icon">
                                            <Whisper
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        Preços praticados na
                                                        loja física, nota fiscal
                                                        ou em Apps quando
                                                        correspondem aos preços
                                                        da loja
                                                    </Tooltip>
                                                }
                                            >
                                                <img src={infoIcon} alt="" />
                                            </Whisper>
                                        </span>
                                    </div>
                                    <div className="channel-app">
                                        <Toggle
                                            size="sm"
                                            checked={channelAppToggle}
                                            id="channel-app-toggle"
                                            onChange={(e) =>
                                                this.handleChannelToggle(
                                                    e,
                                                    'Canal digital',
                                                )
                                            }
                                            style={{ marginRight: '5px' }}
                                        />
                                        <span>Canal digital</span>
                                        <span className="toggle-info-icon">
                                            <Whisper
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={
                                                    <Tooltip>
                                                        Preços praticados em
                                                        sites/apps do varejo
                                                        físico
                                                    </Tooltip>
                                                }
                                            >
                                                <img src={infoIcon} alt="" />
                                            </Whisper>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                        {usuario?.tipoAdicionalContratos?.PAN?.includes(
                            'SB',
                        ) ? (
                            <FlexboxGrid.Item
                                componentClass={Col}
                                lg={9}
                                md={7}
                                sm={24}
                                xs={24}
                            >
                                <div className="ad-container">
                                    <span className="toggle-title">
                                        ANÚNCIOS
                                    </span>
                                    <div className="toggle-container">
                                        <div className="add">
                                            <Toggle
                                                size="sm"
                                                checked={ad}
                                                id="add-toggle"
                                                onChange={(value) =>
                                                    this.handleAdToggle(value)
                                                }
                                                style={{ marginRight: '5px' }}
                                            />
                                            <span>
                                                Somente produtos com anúncio
                                            </span>
                                            <span className="toggle-info-icon">
                                                <Whisper
                                                    placement="bottom"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Veja somente
                                                            produtos com
                                                            informações de
                                                            anúncios
                                                            promocionais na
                                                            análise
                                                        </Tooltip>
                                                    }
                                                >
                                                    <img
                                                        src={infoIcon}
                                                        alt=""
                                                    />
                                                </Whisper>
                                            </span>
                                        </div>
                                    </div>
                                    <Button
                                        appearance="subtle"
                                        className="shopping-brasil-btn"
                                        onClick={this.handleShoppingBrasilSite}
                                    >
                                        <img src={shoppingBrasil} alt="" />
                                    </Button>
                                </div>
                            </FlexboxGrid.Item>
                        ) : null}
                    </FlexboxGrid>
                    <FlexboxGrid>
                        {filtrosAdicionados.length
                            ? filtrosAdicionados.map((filtro, index) => (
                                  <CustomFilter
                                      key={filtro.name}
                                      filtro={filtro}
                                      index={index}
                                      handleDeleteFiltro={
                                          this.handleDeleteFiltro
                                      }
                                      onOpenCustomFilter={onOpenCustomFilter}
                                      onSearchCustomFilter={
                                          onSearchCustomFilter
                                      }
                                  />
                              ))
                            : ''}
                    </FlexboxGrid>
                </Content>

                {showModalSalvarFiltro ? (
                    <ModalSalvarFiltro
                        usuario={usuario}
                        show={showModalSalvarFiltro}
                        onHide={this.closeModalSalvarFiltro}
                        filtroSalvoSelecionado={
                            listaFiltrosSalvos && filtroSalvo
                                ? listaFiltrosSalvos[
                                      listaFiltrosSalvos.findIndex(
                                          (item) => item.id === filtroSalvo,
                                      )
                                  ]
                                : null
                        }
                    />
                ) : null}

                <ModalConfirmation
                    title="Excluir filtro"
                    message={
                        listaFiltrosSalvos && filtroSalvo
                            ? `Deseja deletar o filtro ${
                                  listaFiltrosSalvos[
                                      listaFiltrosSalvos.findIndex(
                                          (item) => item.id === filtroSalvo,
                                      )
                                  ].label
                              }? Esta ação não poderá se desfeita`
                            : null
                    }
                    show={showModalConfirm && filtroSalvo}
                    buttonConfirm="Deletar filtro"
                    buttonCancel="Cancelar"
                    onConfirm={this.deletarFiltroSalvo}
                    onCancel={() => {
                        this.setState({
                            showModalConfirm: false,
                        });
                    }}
                />
                {/* {showModalConfirm && filtroSalvo ? (
                ) : null} */}
            </>
        );
    }
}

const AddFiltroComponent = ({ usuario }) => (
    <Whisper
        trigger="click"
        placement="bottomStart"
        speaker={
            <Speaker usuario={usuario} className="popover-filtro-dinamico" />
        }
    >
        <ButtonToolbar>
            <IconButton id="add-filter" icon={<Icon icon="plus" />}>
                Adicionar filtros
            </IconButton>
        </ButtonToolbar>
    </Whisper>
);

const mapStateToProps = (store) => ({
    filtros: store.painelGeralDataReducer.filtros,
    filtrosAdicionados: store.painelGeralDataReducer.filtrosAdicionados,
    ad: store.painelGeralDataReducer.ad,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addFiltro,
            clearFiltrosPanel,
            deleteFiltro,
            deleteFiltroEndereco,
            searchProdutos,
            selectValueNoCache,
            setDataPanel,
            setErro,
            setFiltrosSalvosPanel,
            setLatLon,
            setLimitProducts,
            setChannel,
            setPriceFilter,
            setAd,
            changeNoCacheValue,
            clearTotalDatapoints,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FiltersArea);
