import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Modal,
    Radio, RadioGroup,
} from 'rsuite';

import { lead } from '../../../../../services/EventLeadService';
import { trackEvent } from '../../../../../utils/MatomoConfig';
import { getFiltrosSalvos, saveFiltro, updateFiltro } from '../services';

class ModalSalvarFiltro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nome: '',
            errors: {},
            filters: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.getModel = this.getModel.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.onClose = this.onClose.bind(this);
        this.sendEventLead = this.sendEventLead.bind(this);
        this.salvarFiltro = this.salvarFiltro.bind(this);
    }

    componentDidMount() {
        this.getSavedFiltersList();
    }

    handleChange(value, e) {
        this.setState({
            [e.target.name]: value,
        });
    }

    handleChangeRadio(value, e) {
        const { filtroSalvoSelecionado } = this.props;

        this.setState({
            [e.target.name]: value,
        });

        if (value === 'SUB') {
            this.setState({
                nome: filtroSalvoSelecionado.label,
            });
        }
    }

    onClose() {
        const { onHide } = this.props;

        this.clearFields();
        onHide();
    }

    getModel() {
        const { nome } = this.state;
        const {
            produto,
            rede,
            tipoLoja,
            uf,
            cidade,
            categoria,
            secao,
            tipoPreco,
            endereco,
            raio,
            origemPreco,
            fabricante,
            canal,
        } = this.props;
        return {
            name: nome,
            filters: {
                rede: rede.value && rede.value.length ? rede.value : null,
                tipoLoja: tipoLoja.value && tipoLoja.value.length ? tipoLoja.value : null,
                uf: uf.value && uf.value.length ? uf.value : null,
                cidade: cidade.value && cidade.value.length ? cidade.value : null,
                categoria: categoria.value && categoria.value.length ? categoria.value : null,
                secao: secao.value && secao.value.length ? secao.value : null,
                gtin: produto.cache && produto.cache.length ? produto.cache.map((item) => item.identificador) : null,
                tipoPreco: tipoPreco.value && tipoPreco.value.length ? tipoPreco.value : null,
                latitude: endereco.latitude ? endereco.latitude : null,
                longitude: endereco.longitude ? endereco.longitude : null,
                endereco: endereco.value && endereco.value.length ? endereco.value : null,
                raio: raio.value ? raio.value : null,
                origemPreco: origemPreco.value && origemPreco.value.length ? origemPreco.value : null,
                fabricante: fabricante.value && fabricante.value.length ? fabricante.value : null,
                canal: canal && canal.length ? canal : ['Canal físico', 'Canal digital'],
            },
        };
    }

    getSavedFiltersList() {
        getFiltrosSalvos().then((response) => {
            if (response) {
                this.setState({
                    filters: response,
                });
            }
        });
    }

    salvarFiltro() {
        const { nome, errors, radioList } = this.state;
        const { filtroSalvoSelecionado, onHide } = this.props;
        if (!nome.length) {
            const nome = document.getElementsByName('nome')[0];
            const error = errors;
            error.nome = 'Campo obrigatório';
            this.setState({ errors: error });
            if (nome) {
                nome.classList.add('invalid-input');
            }
        } else if (filtroSalvoSelecionado && radioList === 'SUB') {
            updateFiltro(filtroSalvoSelecionado.id, this.getModel()).then((response) => {
                if (response) {
                    onHide();
                    Alert.success('Filtro editado com sucesso');
                    this.clearFields();
                }
            });
        } else {
            const { filters } = this.state;

            saveFiltro(this.getModel()).then((response) => {
                if (response) {
                    onHide();
                    Alert.success('Filtro salvo com sucesso');
                    this.clearFields();
                    trackEvent('InfoPanel', 'click-salvar-filtro');

                    if (!filters.length) {
                        this.sendEventLead('filtro1-configurado');
                    }
                }
            });
        }
    }

    clearFields() {
        this.setState({
            radioList: null,
            nome: null,
        });
    }

    sendEventLead(identificador) {
        const { usuario } = this.props;

        lead(identificador, usuario).then(() => {
            console.log(`Evento ${identificador} enviado ao RD Station`);
            this.getSavedFiltersList();
        });
    }

    render() {
        const { show, filtroSalvoSelecionado } = this.props;
        const { radioList, nome, errors } = this.state;

        return (
            <Modal className="modal-salvar-filtro" backdrop="static" show={show} onHide={this.onClose}>
                <Modal.Header>
                    <Modal.Title>Salvar filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        {filtroSalvoSelecionado
                            ? (
                                <FormGroup>
                                    <RadioGroup name="radioList" onChange={this.handleChangeRadio}>
                                        <Radio value="SUB">
                                            Substituir o filtro
                                            {' '}
                                            <b>{filtroSalvoSelecionado?.label}</b>
                                            ?
                                        </Radio>
                                        <Radio value="CRI">Criar novo filtro</Radio>
                                    </RadioGroup>
                                </FormGroup>
                            ) : null}
                        {!filtroSalvoSelecionado || (filtroSalvoSelecionado && radioList)
                            ? (
                                <FormGroup>
                                    <ControlLabel>Nome do filtro</ControlLabel>
                                    <FormControl name="nome" value={nome} onChange={this.handleChange} />
                                    <HelpBlock>{errors?.nome}</HelpBlock>
                                </FormGroup>
                            )
                            : null}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {!filtroSalvoSelecionado || (filtroSalvoSelecionado && radioList)
                        ? (
                            <div style={{ display: 'inline-block', position: 'relative', float: 'left' }}>
                                <span style={{ fontSize: '13px', color: '#8e8e93' }}>*A data escolhida não é salva no filtro</span>
                            </div>
                        )
                        : null}
                    {!filtroSalvoSelecionado || (filtroSalvoSelecionado && radioList)
                        ? (
                            <Button onClick={this.salvarFiltro} appearance="primary">
                                {radioList === 'SUB' ? 'Substituir filtro' : 'Salvar filtro'}
                            </Button>
                        ) : null}
                    <Button onClick={this.onClose} appearance="default">
                        Descartar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (store) => ({
    produto: store.painelGeralDataReducer.produto,
    rede: store.painelGeralDataReducer.rede,
    tipoLoja: store.painelGeralDataReducer.tipoLoja,
    fabricante: store.painelGeralDataReducer.fabricante,
    tipoProduto: store.painelGeralDataReducer.tipoProduto,
    secao: store.painelGeralDataReducer.secao,
    categoria: store.painelGeralDataReducer.categoria,
    mecanica: store.painelGeralDataReducer.mecanica,
    grupoMidia: store.painelGeralDataReducer.grupoMidia,
    midia: store.painelGeralDataReducer.midia,
    uf: store.painelGeralDataReducer.uf,
    cidade: store.painelGeralDataReducer.cidade,
    endereco: store.painelGeralDataReducer.endereco,
    tipoPreco: store.painelGeralDataReducer.tipoPreco,
    origemPreco: store.painelGeralDataReducer.origemPreco,
    dataInicio: store.painelGeralDataReducer.dataInicio,
    dataFim: store.painelGeralDataReducer.dataFim,
    raio: store.painelGeralDataReducer.raio,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalSalvarFiltro);
