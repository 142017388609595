import React, { ReactNode } from 'react';
import { Button, Modal } from 'rsuite';
import styles from './styles.module.scss';

export interface IModalOnDemandPlan {
    onCancel():void;
    onConfirm():void;
    show: boolean;
    contentType: boolean;
    content1: ReactNode;
    content2: ReactNode;
    canSave: boolean;
}

export const ModalEditRequest = ({
    show,
    onCancel,
    onConfirm,
    content1,
    content2,
    contentType,
    canSave,
}: IModalOnDemandPlan) => (
    <Modal
        overflow={false}
        backdrop="static"
        show={show}
        className={styles['modal-container']}
        onHide={onCancel}
    >
        <Modal.Header closeButton />
        {contentType ? content1 : content2}

        <Modal.Footer>
            <Button onClick={onCancel} appearance="ghost">
                Fechar
            </Button>
            <Button onClick={onConfirm} appearance="primary" disabled={contentType && !canSave}>
                {contentType ? 'Salvar' : 'Editar solicitação'}
            </Button>
        </Modal.Footer>
    </Modal>
);
