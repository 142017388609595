import _ from 'lodash';
import React, { useReducer } from 'react';

type Item = string;

export type FilterValueState<T extends string> = Partial<Record<T, Item[]>>;

type Action<T extends string> =
    | {
          type: 'ADD';
          key: T;
          payload: Item;
      }
    | {
          type: 'SET';
          key: T;
          payload: Item[];
      }
    | {
          type: 'RESET';
      }
    | {
          type: 'RESET_BY_KEY';
          key: T;
      };

const initialState: FilterValueState<never> = {};

const reducer = <T extends string>(
    state: FilterValueState<T>,
    action: Action<T>,
): FilterValueState<T> => {
    switch (action.type) {
        case 'ADD':
            return {
                ...state,
                [action.key]: _.union([
                    ...(state[action.key] ?? []),
                    action.payload,
                ]),
            };
        case 'SET':
            return {
                ...state,
                [action.key]: action.payload,
            };
        case 'RESET':
            return initialState;
        case 'RESET_BY_KEY':
            return _.omit(state, action.key) as FilterValueState<T>;
        default:
            return state;
    }
};

const ADD_DATA = <T extends string>(key: T, payload: Item) => {
    return {
        type: 'ADD',
        key,
        payload,
    } as const;
};

const SET_DATA = <T extends string>(key: T, payload: Item[]) => {
    return {
        type: 'SET',
        key,
        payload,
    } as const;
};

const RESET_DATA = () => {
    return {
        type: 'RESET',
    } as const;
};

const RESET_DATA_BY_KEY = <T extends string>(key: T) => {
    return {
        type: 'RESET_BY_KEY',
        key,
    } as const;
};

export const useFilterValue = <T extends string>() => {
    const [data, dispatch] = useReducer(
        reducer as (
            state: FilterValueState<T>,
            action: Action<T>,
        ) => FilterValueState<T>,
        initialState as FilterValueState<T>,
    );

    const onAddData = (key: T, payload: Item) => {
        dispatch(ADD_DATA(key, payload));
    };

    const onSetData = (key: T, payload: Item[]) => {
        dispatch(SET_DATA(key, payload));
    };

    const onResetData = () => {
        dispatch(RESET_DATA());
    };

    const onResetByKey = (key: T) => {
        dispatch(RESET_DATA_BY_KEY(key));
    };

    return [
        React.useMemo(() => data, [data]),
        {
            add: onAddData,
            set: onSetData,
            reset: onResetData,
            resetByKey: onResetByKey,
        },
    ] as const;
};
