import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
    Checkbox,
    Dropdown,
    Popover,
    Table,
    Toggle,
    Whisper,
} from 'rsuite';
import {
    reducerOnDemandSelector,
} from '../../../../../../reducers/reducerOnDemand';
import { LastWeekPriceStatus } from '../LastWeekPriceStatus/LastWeekPriceStatus';
import { TableOnDemandProps, TableResponseContentType } from '../../types';
import * as utils from '../../utils';
import styles from './styles.module.scss';

const { Column, HeaderCell, Cell } = Table;
const { Pagination } = Table;

const bulkSelectionBackgroundStyle = {
    backgroundColor: '#F5F9FE',
};

const MenuPopover = ({ onSelect, disabled, ...rest }) => (
    <Popover {...rest} full>
        <Dropdown.Menu onSelect={onSelect} style={{ width: '220px' }}>
            <Dropdown.Item eventKey={1} className="row-option-menu-first">
                Ver e editar solicitação
            </Dropdown.Item>
            <Dropdown.Item eventKey={2} disabled={disabled}>
                Extração dos resultados .CSV
            </Dropdown.Item>
            <Dropdown.Item eventKey={3} disabled={disabled}>
                Extração dos resultados .XLSX
            </Dropdown.Item>
        </Dropdown.Menu>
    </Popover>
);

export const TableOnDemand = ({
    requests,
    summaryData,
    onChangePage,
    onChangeCheckAll,
    onChangeCheck,
    onSelectTableIndividualItem,
    onSortColumn,
    onChangeIndividualStatus,
    onClickSelectWholeList,
}: TableOnDemandProps) => {
    const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
    const [columnType, setColumnType] = useState<string | undefined>('');

    const { tableRequestBody, checkedItems } = useSelector(reducerOnDemandSelector);

    const isChecked = checkedItems?.length;
    const indeterminate = checkedItems?.length && checkedItems?.length !== requests?.size;
    const headerStyle = checkedItems?.length ? bulkSelectionBackgroundStyle : {};
    const cannotDownload = summaryData?.pricesReturned === 0 || summaryData?.pricesReturned === null;

    const handleSortTableColumn = (column, type) => {
        setSortType(type);
        setColumnType(column);
        onSortColumn(column, type);
    };

    useEffect(() => {
        if (tableRequestBody.requestdAll) {
            // Quando o usuario selecionar o total de itens existentes (totalElements)
            onClickSelectWholeList();
        }
    }, [tableRequestBody.requestdAll, requests?.content]);

    return (
        <>
            <Table
                className={styles['requests-table']}
                height={500}
                data={requests?.content}
                bordered
                sortColumn={columnType}
                sortType={sortType}
                onSortColumn={handleSortTableColumn}
            >
                <Column width={55} align="center">
                    <HeaderCell style={headerStyle}>
                        <Checkbox
                            indeterminate={indeterminate}
                            checked={
                                requests?.content.length
                                === checkedItems?.length
                            }
                            onChange={onChangeCheckAll}
                        />
                    </HeaderCell>
                    <Cell>
                        {(rowData: TableResponseContentType) => (
                            <Checkbox
                                value={rowData?.idSolicitacao}
                                onChange={onChangeCheck}
                                checked={checkedItems?.some(
                                    (item: string) => item
                                        === rowData?.idSolicitacao,
                                )}
                            />
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'ID da solicitação'}
                    </HeaderCell>
                    <Cell dataKey="idSolicitacao" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell
                        style={headerStyle}
                        className={styles['double-word']}
                    >
                        {isChecked ? null : (
                            <span>
                                <p>Código de barras /</p>
                                <p>Palavra chave</p>
                            </span>
                        )}
                    </HeaderCell>
                    <Cell>
                        {(rowData: TableResponseContentType) => {
                            const productType = rowData?.gtin !== '' && rowData?.gtin !== null
                                ? rowData?.gtin
                                : rowData?.palavraChave;

                            return <span>{productType}</span>;
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={1} sortable={!isChecked}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'Data criação'}
                    </HeaderCell>
                    <Cell dataKey="dataCriacao">
                        {(rowData: TableResponseContentType) => {
                            const date = rowData?.dataCriacao
                                ? utils.formatDate(rowData.dataCriacao)
                                : null;
                            return <span>{date}</span>;
                        }}
                    </Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'Criador'}
                    </HeaderCell>
                    <Cell dataKey="criador" />
                </Column>
                <Column width={55}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'UF'}
                    </HeaderCell>
                    <Cell dataKey="uf" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'Cidade'}
                    </HeaderCell>
                    <Cell dataKey="cidade" />
                </Column>
                <Column width={90} sortable={!isChecked}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'Raio (KM)'}
                    </HeaderCell>
                    <Cell dataKey="raio">
                        {(rowData: TableResponseContentType) => {
                            const radius = rowData?.raio
                                ? parseInt(rowData.raio, 10)
                                : null;
                            return <span>{radius}</span>;
                        }}
                    </Cell>
                </Column>
                <Column width={120} sortable={!isChecked}>
                    <HeaderCell
                        style={headerStyle}
                        className={styles['double-word']}
                    >
                        {isChecked ? null : (
                            <span>
                                <p>Total de preços</p>
                                <p>retornados</p>
                            </span>
                        )}
                    </HeaderCell>
                    <Cell dataKey="totalPrecosRetornados">
                        {(rowData: TableResponseContentType) => (
                            <span>
                                {rowData.totalPrecosRetornados || '0'}
                            </span>
                        )}
                    </Cell>
                </Column>
                <Column width={140} sortable={!isChecked}>
                    <HeaderCell
                        style={headerStyle}
                        className={styles['double-word']}
                    >
                        {isChecked ? null : (
                            <span>
                                <p>Preços retornados</p>
                                <p>na última semana</p>
                            </span>
                        )}
                    </HeaderCell>
                    <Cell dataKey="precosUltimaSemana">
                        {(rowData: TableResponseContentType) => (
                            <div className={styles['price-week-wrapper']}>
                                {rowData.precosUltimaSemana || '0'}
                                <LastWeekPriceStatus
                                    lastWeekPrice={rowData.precosUltimaSemana}
                                    beforeLastWeekPrice={rowData.precosPenultimaSemana}
                                />
                            </div>
                        )}
                    </Cell>
                </Column>
                <Column width={60}>
                    <HeaderCell style={headerStyle}>
                        {isChecked ? null : 'Status'}
                    </HeaderCell>
                    <Cell>
                        {(rowData: TableResponseContentType) => (
                            <Toggle
                                size="sm"
                                checked={rowData?.status}
                                onChange={(e) => onChangeIndividualStatus(
                                        rowData?.idSolicitacao,
                                        e,
                                    )}
                            />
                        )}
                    </Cell>
                </Column>
                <Column width={60}>
                    <HeaderCell style={headerStyle} />
                    <Cell className={styles['row-options-cell']}>
                        {(rowData: TableResponseContentType) => (
                            <Whisper
                                placement="bottomEnd"
                                trigger="click"
                                speaker={(
                                    <MenuPopover
                                        disabled={cannotDownload}
                                        onSelect={(value: number) => onSelectTableIndividualItem(
                                                value,
                                                rowData.idSolicitacao,
                                            )}
                                    />
                                  )}
                            >
                                <Button className={styles['row-options-btn']}>
                                    <MoreVertIcon fontSize="small" />
                                </Button>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                className={styles['requests-pagination']}
                activePage={requests.number + 1}
                displayLength={requests?.size}
                showLengthMenu={false}
                total={requests?.totalElements}
                onChangePage={(page) => onChangePage(page)}
                renderTotal={() => `Exibindo ${requests?.content?.length} de ${requests?.totalElements} solicitações.`}
            />
        </>
  );
};
