import axios from 'axios';
import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';

import api from '../../../../utils/API';

const isaDashboardURL = process.env.REACT_APP_ISA_URL;

export const painelDownload = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/download`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
        responseType: 'arraybuffer',
    })
        .then((res) => res)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `painel_geral.${data.extensao}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
    'download-infopanel',
);

export const downloadBoxplot = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/agregado/download`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
        responseType: 'arraybuffer',
    })
        .then((res) => res)
        .then((download) => {
            if (download.status === 200) {
                const blob = new Blob([download.data]);
                const hiddenElement = document.createElement('a');
                hiddenElement.href = window.URL.createObjectURL(blob);
                hiddenElement.target = '_blank';
                hiddenElement.download = `comparacao_produto.${data.options.download.extension}`;
                hiddenElement.click();
            } else {
                return download;
            }
        }),
);

export const validateItens = (nivel, data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros/validar`,
    method: 'GET',
    expectToken: true,
    params: {
        field: nivel || null,
        query: data.query || null,
        size: data.size,
        dataInicio: data.dataInicio ? data.dataInicio : null,
        dataFim: data.dataFim ? data.dataFim : null,
        rede: data.rede ? data.rede : null,
        tipoLoja: data.tipoLoja ? data.tipoLoja : null,
        uf: data.uf ? data.uf : null,
        cidade: data.cidade ? data.cidade : null,
        categoria: data.categoria ? data.categoria : null,
        secao: data.secao ? data.secao : null,
        gtin: data.gtin ? data.gtin : null,
        tipoPreco: data.tipoPreco ? data.tipoPreco : null,
        latitude: data.latitude ? data.latitude : null,
        longitude: data.longitude ? data.longitude : null,
        raio: data.raio ? data.raio : null,
    },
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    },
}).then((resp) => resp.data);

let cancelTokenFiltros;
export const getItensFiltros = (data) => {
    if (typeof cancelTokenFiltros !== typeof undefined) {
        cancelTokenFiltros.cancel('Operation canceled due to new request.');
    }

    cancelTokenFiltros = axios.CancelToken.source();

    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros`,
            method: 'POST',
            data: JSON.stringify(data),
            cancelToken: cancelTokenFiltros.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'panel-filter',
    );
};

export const getFiltrosSalvos = (search) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros_salvos`,
        method: 'GET',
        expectToken: true,
        params: {
            query: search || null,
        },
    }).then((resp) => resp.data),
);

export const getFiltroSalvo = (id) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros_salvos/${id}`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const saveFiltro = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros_salvos`,
    method: 'POST',
    expectToken: true,
    data: JSON.stringify(data),
}).then((resp) => resp.data);

export const updateFiltro = (id, data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros_salvos/${id}`,
    method: 'PUT',
    expectToken: true,
    data: JSON.stringify(data),
}).then((resp) => resp.data);

export const deleteFiltro = (id) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros_salvos/${id}`,
    method: 'DELETE',
    expectToken: true,
}).then((resp) => resp);

let cancelTokenProduto;
export const getProduto = (data, size) => {
    if (typeof cancelTokenProduto !== typeof undefined) {
        cancelTokenProduto.cancel('Operation canceled due to new request.');
    }

    cancelTokenProduto = axios.CancelToken.source();

    return trackPromise(
        api({
            url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros/produto`,
            method: 'POST',
            data: JSON.stringify(data),
            params: {
                size: size || 50,
            },
            cancelToken: cancelTokenProduto.token,
            expectToken: true,
        }).then((resp) => resp.data),
        'panel-filter',
    );
};

export const validateProdutos = (data, identificadores) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/filtros/produto/validar`,
    method: 'POST',
    expectToken: true,
    params: {
        query: data.query || null,
        size: data.size,
        dataInicio: data.dataInicio ? data.dataInicio : null,
        dataFim: data.dataFim ? data.dataFim : null,
        rede: data.rede ? data.rede : null,
        tipoLoja: data.tipoLoja ? data.tipoLoja : null,
        uf: data.uf ? data.uf : null,
        cidade: data.cidade ? data.cidade : null,
        categoria: data.categoria ? data.categoria : null,
        secao: data.secao ? data.secao : null,
        tipoPreco: data.tipoPreco ? data.tipoPreco : null,
        latitude: data.latitude ? data.latitude : null,
        longitude: data.longitude ? data.longitude : null,
        raio: data.raio ? data.raio : null,
    },
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    data: JSON.stringify(identificadores),
}).then((resp) => resp.data);

export const getIframe = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/embed`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getColumnsExportFile = () => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/dimensoes`,
    method: 'GET',
    expectToken: true,
}).then((resp) => resp.data);

export const getColumnsPreview = () => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/preview`,
        method: 'GET',
        expectToken: true,
    }).then((resp) => resp.data),
    'download-infopanel',
);

export const getAgregado = (data, pagination) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/agregado`,
        method: 'POST',
        expectToken: true,
        params: pagination
            ? {
                size: pagination.size,
                page: pagination.page,
            }
            : null,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getAgregadoWithCount = (data, pagination) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/agregado/count_store_chain`,
        method: 'POST',
        expectToken: true,
        params: pagination
            ? {
                size: pagination.size,
                page: pagination.page,
            }
            : null,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getTopFive = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/agregado/top-five`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getPinPoints = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/pinpoints`,
        method: 'POST',
        expectToken: true,
        params: {
            size: 3000,
        },
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getEndereco = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/location/autocomplete`,
    method: 'GET',
    expectToken: true,
    params: {
        query: data.query ? data.query : null,
        session_token: data.token ? data.token : null,
    },
}).then((resp) => resp.data);

export const getPlace = (data) => api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/location/place`,
    method: 'GET',
    params: {
        place_id: data.place_id ? data.place_id : null,
        session_token: data.token ? data.token : null,
    },
}).then((resp) => resp.data);

export const getShareTokens = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/share`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getUsersEmail = (name) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CADASTROS_API_URL}/usuarios/pessoas/servicos/autocomplete`,
        method: 'GET',
        expectToken: true,
        params: {
            query: name,
        },
    }).then((resp) => resp.data),
);

export const getAgregadoPublic = (data, size) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/public/painel_geral/agregado`,
        method: 'POST',
        params: {
            size,
        },
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getAgregadoWithCountPublic = (data, size) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/public/painel_geral/agregado/count_store_chain`,
        method: 'POST',
        params: {
            size,
        },
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getAgregadoTopFivePublic = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/public/painel_geral/agregado/top-five`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getMapPinpointsPublic = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/public/painel_geral/pinpoints?size=3000`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getSharedStatePublic = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/public/painel_geral/sharedState`,
        method: 'POST',
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const sendShareAnalysisEmail = (data) => trackPromise(
    api({
        url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/share/email`,
        method: 'POST',
        expectToken: true,
        data: JSON.stringify(data),
    }).then((resp) => resp.data),
);

export const getDataCount = (data) => trackPromise(api({
    url: `${process.env.REACT_APP_CLIENTE_URL}/painel_geral/search_count`,
    method: 'POST',
    expectToken: true,
    data: JSON.stringify(data),
}).then((resp) => resp.data), 'totalizer');

export const productDashboardService = (data, pageData) => trackPromise(
    api({
        url: `${isaDashboardURL}/dashboard/produtos`,
        method: 'POST',
        params: {
            size: pageData.size,
            page: pageData.page,
        },
        data: JSON.stringify(data),
        expectToken: true,
    }).then((res) => res),
);

export const productDashboardDownloadService = (data) => trackPromise(
    api({
        url: `${isaDashboardURL}/dashboard/produtos/download`,
        method: 'POST',
        data: JSON.stringify(data),
        expectToken: true,
        responseType: 'arraybuffer',
    }),
);
