import Chart from 'chart.js';
import moment from 'moment';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Button,
    ButtonToolbar,
    CheckPicker,
    Checkbox,
    Col,
    Container,
    Content,
    Dropdown,
    FlexboxGrid,
    FormGroup,
    HelpBlock,
    Icon,
    IconButton,
    Toggle,
    Tooltip,
    Whisper,
} from 'rsuite';

import {
    addFiltroHistorico, deleteFiltroHistorico, updateFilterDataHistorico, updateFilterValue,
} from '../../../../actions/actionsHistoricoPreco';
import Autocomplete2 from '../../../../components/AutoComplete2';
import { InputFilter, MoreFilterDropdown } from '../../../../components/CustomFilter';
import DataRangePicker from '../../../../components/DataRangePicker';
import { LoadingSpiner } from '../../../../components/LoadingSpinner';
import { getHistorico } from '../../../../services/HistoricoPrecoService';
import * as LojaService from '../../../../services/LojaService';
import * as ProdutoService from '../../../../services/ProdutoService';

function getLinkFoto(linkFoto) {
    if (linkFoto) return linkFoto.replace('https://s3.amazonaws.com/infoprice-prd-repository', 'https://d1kkb7tj44tam6.cloudfront.net');
    return '';
}

const allBorderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(230, 107, 149, 1)',
    'rgba(116, 135, 236, 1)',
    'rgba(134, 236, 138, 1)',
    'rgba(177, 166, 71, 1)',
    'rgba(255, 152, 0, 1)',
    'rgba(119, 212, 224, 1)',
    'rgba(113, 180, 232, 1)',
    'rgba(120, 226, 216, 1)',
    'rgba(225, 87, 34, 1)',
    'rgba(206, 114, 222, 1)',
    'rgba(226, 120, 140, 1)',
    'rgba(226, 154, 120, 1)',
    'rgba(245, 81, 217, 1)',
    'rgba(204, 245, 81, 1)',
];

class HistoricoPrecoPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            label: [],
            dados: [],
            filtroLojas: [],
            lojas: [],
            filtroProduto: [],
            produtos: [],
            data: [],
            cacheData: [],
            dadosBrutos: [],
            maxValue: null,
            first: true,
            pageSizeLoja: 100,
            showErroLoja: false,
            errors: {},
            expurgo: true,
            allValueLojas: [],
            checkAllLojas: false,
            indeterminateLojas: false,
        };

        this.createChart = this.createChart.bind(this);
        this.getData = this.getData.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleExpurgo = this.handleExpurgo.bind(this);
        this.handleCheckAllLojas = this.handleCheckAllLojas.bind(this);
        this.handleFilterLoja = this.handleFilterLoja.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectMoreFilter = this.handleSelectMoreFilter.bind(this);
    }

    componentDidUpdate() {
        this.props.history.listen((location, action) => {
            if (document.getElementById('chartjs-tooltip')) {
                document.getElementById('chartjs-tooltip').style.opacity = 0;
            }
        });
    }

    chartRef = React.createRef();

    createChart() {
        Chart.Tooltip.positioners.custom = function (elements, position) {
            if (!elements.length) {
                return false;
            }
            let offset = 0;
            let offsetY = 0;
            if (elements[0]._chart.width / 2 > position.x) {
                offset = 5;
            } else {
                offset = -328;
            }
            if ((elements[0]._chart.height / 2) > position.y) {
                offsetY = 10;
            } else {
                offsetY = -(position.y * 0.5);
            }

            return {
                x: position.x + offset,
                y: position.y + offsetY,
            };
        };

        Chart.defaults.global.hover.animationDuration = 0;

        Chart.plugins.register({
            afterDraw(chart) {
                if (chart.data.datasets.length === 0) {
                    // No data is present
                    const { ctx } = chart.chart;
                    const { width } = chart.chart;
                    const { height } = chart.chart;
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = "20px 'Roboto'";
                    ctx.fillText('Não existem dados a serem visualizados', width / 2, height / 2);
                    ctx.restore();
                }
            },
        });

        const myChartRef = this.chartRef.current.getContext('2d');

        this.myChart = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: this.state.label,
                datasets: this.state.dados,
            },
            options: {

                scales: {
                    yAxes: [{
                        ticks: {
                            callback(label, index, labels) {
                                return label.toString().replace('.', ',');
                            },
                            max: this.state.maxValue,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Preço',
                            fontSize: 20,
                        },

                    }],
                    xAxes: [{
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                day: 'DD/MM/YYYY',
                            },
                        },
                    }],
                },
                elements: {
                    line: {
                        fill: false,
                        tension: 0,
                    },

                },
                events: ['click', 'touchstart'],
                legend: {
                    labels: {
                        filter(item, chart) {
                            return !item.text.includes('hide');
                        },
                        generateLabels(chart) {
                            const { data } = chart;
                            if (data.datasets.length) {
                                return data.datasets.map((item, i) => {
                                    const ds = item.data[0];
                                    return {
                                        text: `${item.label} - ${ds.lojaDescricao}`,
                                        fillStyle: item.backgroundColor,
                                        strokeStyle: item.borderColor,
                                    };
                                });
                            }
                            return [];
                        },
                    },
                    onClick: (e) => e.stopPropagation(),
                    position: 'top',
                    align: 'start',
                },
                tooltips: {
                    mode: 'nearest',
                    intersect: true,
                    position: 'custom',
                    enabled: false,
                    animationDuration: 0,
                    custom: this.myChartTooltip(),
                },
                hover: {
                    mode: 'x',
                },
            },
        });
    }

    myChartTooltip() {
        const { dados } = this.state;
        return function (tooltipModel) {
            let tooltipEl = document.getElementById('chartjs-tooltip');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div class="chart-tooltip"></div>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            function getBody(bodyItem) {
                return bodyItem.lines[0];
            }

            // Set Text
            if (tooltipModel.body) {
                const bodyLines = tooltipModel.body.map(getBody);
                const lojas = [];
                bodyLines.forEach((item, index) => {
                    lojas.push(item.substr(0, item.indexOf(':')).replace('hide', ''));
                });
                const tooltipProduto = dados.filter((item) => {
                    return lojas.indexOf(item.loja) !== -1;
                });

                let innerHtml = '<div>';

                tooltipProduto.forEach((item, indexProduto) => {
                    item.data.forEach((produto, indexData) => {
                        if (produto.x === tooltipModel.title[0] && produto.y !== null && (item.label.indexOf('hide') === -1 || produto.hadNoData)) {
                            innerHtml += `<h1>${produto.loja} - ${produto.lojaDescricao}</h1>`
                                + `<p class="date-coleta">Data: ${moment(produto.x, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>`;

                            if (produto.linkFoto && produto.y && produto.tipoColeta !== 'PP') {
                                innerHtml += `<div class="div-img"><img src="${produto.linkFoto}"></div>`;
                            }
                            if (produto.linkFotoSugestao && produto.y && produto.tipoColeta === 'PP') {
                                innerHtml += `<div class="div-img"><img src="${produto.linkFotoSugestao}"></div>`;
                            }
                            if (produto.tipoPromocao) {
                                innerHtml += `<div class="div-promo"><p>EM PROMOÇÃO: ${produto.tipoPromocao}</p></div>`;
                            }
                            if (produto.clubeDesconto) {
                                innerHtml += '<div class="div-clube-desconto"><p>CLUBE DE DESCONTO</p></div>';
                            }
                            innerHtml += '<div class="mg-bottom-15">'
                                + `<p class="label-item">Produto</p><p class="item-description">${produto.descricaoCliente}</p>`
                                + '</div>';
                            if (produto.tipoColeta === 'PP' && produto.descricaoSugestao) {
                                innerHtml += '<div class="mg-bottom-15">'
                                    + `<p class="label-item">Produto coletado</p><p class="item-description">${produto.descricaoSugestao}</p>`
                                    + '</div>';
                            }
                            innerHtml += '<div class="mg-bottom-15">'
                                + `<p class="label-item">Código do produto</p><p class="item-description">${produto.gtinCliente}</p>`
                                + '</div>';
                            if (produto.tipoColeta === 'PP' && produto.gtinSugestao) {
                                innerHtml += '<div class="mg-bottom-15">'
                                    + `<p class="label-item">Código coletado</p><p class="item-description">${produto.gtinSugestao}</p>`
                                    + '</div>';
                            }

                            if (produto.dataValidade) {
                                innerHtml += '<div class="mg-bottom-15">'
                                    + `<p class="label-item">Data de validade</p><p class="item-description">${moment(produto.dataValidade).format('DD/MM/YYYY')}</p>`
                                    + '</div>';
                            }
                            if (produto.y && !produto.hadNoData) {
                                innerHtml += '<div class="mg-bottom-15 inline-flex">'
                                    + `<span class="mg-right-15"><p class="label-item">Preço varejo</p><p class="item-description">R$ ${produto.y.toString().replace('.', ',')}</p></span>`;
                            } if (!produto.y || produto.hadNoData) {
                                innerHtml += '<div class="mg-bottom-15 inline-flex">'
                                    + '<span class="mg-right-15"><p class="label-item">Preço varejo</p><p class="item-description">R$ -- (produto não encontrado)</p></span>';
                            }
                            if (produto.precoAtacado) {
                                innerHtml += `<span class="mg-right-15"><p class="label-item">Preço atacado</p><p class="item-description">R$ ${produto.precoAtacado.toString().replace('.', ',')}</p></span>`
                                    + `<span class="mg-right-15"><p class="label-item">A partir de</p><p class="item-description">${produto.gatilhoAtacado}</p></span>`;
                            }
                            innerHtml += '</div>';
                            innerHtml += '<div class="text-center"><hr></div>';
                        }
                    });
                });
                innerHtml = innerHtml.substring(0, innerHtml.lastIndexOf('<div class="text-center"><hr></div>'));
                innerHtml += '</div>';

                const tableRoot = tooltipEl.querySelector('div');
                tableRoot.innerHTML = innerHtml;
            }

            const position = this._chart.canvas.getBoundingClientRect();

            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
            tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
        };
    }

    updateTooltip() {
        this.myChart.options.tooltips.custom = this.myChartTooltip();
    }

    handleValidation() {
        const errors = {};
        let formIsValid = true;

        if (!this.state.data.length) {
            formIsValid = false;
            errors.data = 'Campo obrigatório';
            const data = document.getElementsByName('data')[0];
            if (data) {
                data.classList.add('invalid-input');
            }
        }

        if (!this.state.filtroProduto.length) {
            formIsValid = false;
            errors.produto = 'Campo obrigatório';
            const produto = document.getElementsByName('produto')[0];
            if (produto) {
                produto.classList.add('invalid-input');
            }
        }

        if (!this.state.filtroLojas.length) {
            formIsValid = false;
            errors.lojas = 'Campo obrigatório';
            const lojas = document.getElementsByClassName('rs-picker-input rs-picker-toggle-wrapper rs-picker-placement-bottom-start rs-picker-block rs-picker-tag')[0];
            if (lojas) {
                lojas.classList.add('invalid-input');
            }
        }

        this.setState({ errors });
        return formIsValid;
    }

    getRequestBody() {
        return {
            data_inicio: this.state.data[0],
            data_fim: this.state.data[1],
            lojas: this.state.filtroLojas,
            produto: this.state.filtroProduto[0],
            expurgo: this.state.expurgo,
        };
    }

    getData() {
        if (this.handleValidation()) {
            const requestObject = this.getRequestBody();

            trackPromise(
                getHistorico(requestObject)
                    .then((resultSet) => {
                        if (resultSet?.data?.length) {
                            this.setState({
                                dadosBrutos: resultSet.data,
                            });
                            const arrayPrecos = [];
                            this.state.dadosBrutos.forEach((item) => {
                                arrayPrecos.push(item['BiDesagregado.preco']);
                            });
                            const max = Math.max(...arrayPrecos);
                            this.setState({
                                maxValue: Math.round(max * 1.2),
                            });
                            function getLojaData(item) {
                                return {
                                    x: moment(item['BiDesagregado.dataColeta']).format('YYYY-MM-DD'),
                                    y: item['BiDesagregado.preco'] && item['BiDesagregado.presenca'] ? Number(item['BiDesagregado.preco']).toFixed(2) : null,
                                    gtinCliente: item['BiDesagregado.gtinCliente'] ? item['BiDesagregado.gtinCliente'] : null,
                                    descricaoCliente: item['BiDesagregado.descricaoCliente'] ? item['BiDesagregado.descricaoCliente'] : null,
                                    linkFoto: item['BiDesagregado.linkFoto'] && item['BiDesagregado.presenca'] ? getLinkFoto(item['BiDesagregado.linkFoto']) : null,
                                    precoAtacado: item['BiDesagregado.precoAtacado'] && item['BiDesagregado.presenca'] ? Number(item['BiDesagregado.precoAtacado']).toFixed(2) : null,
                                    gatilhoAtacado: item['BiDesagregado.gatilhoAtacado'] && item['BiDesagregado.presenca'] ? Number(item['BiDesagregado.gatilhoAtacado']).toFixed(0) : null,
                                    tipoPromocao: item['BiDesagregado.tipoPromocao'] && item['BiDesagregado.presenca'] ? item['BiDesagregado.tipoPromocao'] : null,
                                    clubeDesconto: item['BiDesagregado.clubeDesconto'] && item['BiDesagregado.presenca'] ? item['BiDesagregado.clubeDesconto'] : null,
                                    precoDe: item['BiDesagregado.precoDe'] && item['BiDesagregado.presenca'] ? Number(item['BiDesagregado.precoDe']).toFixed(2) : null,
                                    dataValidade: item['BiDesagregado.dataValidade'] && item['BiDesagregado.presenca'] ? item['BiDesagregado.dataValidade'] : null,
                                    loja: item['BiDesagregado.lojaCliente'] ? item['BiDesagregado.lojaCliente'] : null,
                                    lojaDescricao: item['BiDesagregado.lojaClienteDescricao'] ? item['BiDesagregado.lojaClienteDescricao'] : null,
                                    gtinSugestao: item['BiDesagregado.gtinSugestao'] && item['BiDesagregado.presenca'] ? item['BiDesagregado.gtinSugestao'] : null,
                                    descricaoSugestao: item['BiDesagregado.descricaoSugestao'] && item['BiDesagregado.presenca'] ? item['BiDesagregado.descricaoSugestao'] : null,
                                    linkFotoSugestao: item['BiDesagregado.linkFotoSugestao'] && item['BiDesagregado.presenca'] ? getLinkFoto(item['BiDesagregado.linkFotoSugestao']) : null,
                                    tipoColeta: item['BiDesagregado.tipoColeta'] ? item['BiDesagregado.tipoColeta'] : null,
                                };
                            }

                            const newArrayLoja = {};
                            resultSet.data.forEach((item, index) => {
                                if (newArrayLoja[item['BiDesagregado.lojaCliente']]) {
                                    newArrayLoja[item['BiDesagregado.lojaCliente']].data.push(getLojaData(item));
                                } else {
                                    newArrayLoja[item['BiDesagregado.lojaCliente']] = { label: item['BiDesagregado.lojaCliente'], data: [], loja: item['BiDesagregado.lojaCliente'] };
                                    newArrayLoja[item['BiDesagregado.lojaCliente']].data.push(getLojaData(item));
                                }
                            });

                            const lojasMap = Object.values(newArrayLoja);

                            lojasMap.forEach((item, index) => {
                                item.borderColor = allBorderColor[index];
                                item.backgroundColor = allBorderColor[index];
                                item.spanGaps = false;
                                item.pointStyle = getPointStyle;
                                item.pointBackgroundColor = getPointColor;
                                item.pointBorderWidth = getPointBorderWidth;
                                item.pointBorderColor = getPointBorderColor;
                                item.radius = getPointRadius;
                                item.pointRadius = getPointRadius;
                                item.hitRadius = 10;
                            });

                            function getPointRadius(context) {
                                const produto = context.dataset.data[context.dataIndex];
                                if (produto.tipoPromocao) {
                                    return 5;
                                }
                                return 7;
                            }

                            function getPointStyle(context) {
                                return 'circle';
                            }

                            function getPointColor(context) {
                                const produto = context.dataset.data[context.dataIndex];
                                const preco = produto.y;
                                const { hadNoData } = produto;
                                if (preco === null || preco === 0 || hadNoData) {
                                    return context.dataset.backgroundColor.replace(/[^,]+(?=\))/, '0.2');
                                }
                                if (produto.tipoPromocao) {
                                    return 'rgb(255,255,255,1)';
                                }
                            }

                            function getPointBorderWidth(context) {
                                const produto = context.dataset.data[context.dataIndex];
                                const preco = produto.y;
                                const { hadNoData } = produto;
                                if (preco === null || preco === 0 || hadNoData) {
                                    return 2;
                                }

                                if (produto.tipoPromocao) {
                                    return 5.5;
                                }
                                return 1;
                            }

                            function getPointBorderColor(context) {
                                return context.dataset.backgroundColor;
                            }

                            function getPontoMedio(indice, lista) {
                                let anterior = null;
                                let proximo = null;
                                let dateIndiceInicio = new Date(lista[0].x).getTime();
                                let dateIndiceFinal = new Date(lista[lista.length - 1].x).getTime();

                                for (let index = indice - 1; index >= 0; index--) {
                                    const element = lista[index].y;
                                    if (element !== null) {
                                        anterior = Number(element);
                                        dateIndiceInicio = new Date(lista[index].x).getTime();
                                        break;
                                    }
                                }

                                for (let indexFinal = indice + 1; indexFinal < lista.length; indexFinal++) {
                                    const elementFinal = lista[indexFinal].y;
                                    if (elementFinal !== null) {
                                        proximo = Number(elementFinal);
                                        dateIndiceFinal = new Date(lista[indexFinal].x).getTime();
                                        break;
                                    }
                                }

                                if (anterior === null && proximo === null) return 0;

                                if (anterior === null || proximo === null) return anterior === null ? proximo : anterior;

                                const deltaX = dateIndiceFinal - dateIndiceInicio;
                                const deltaY = proximo - anterior;
                                const m = deltaY / deltaX;
                                const dateIndice = new Date(lista[indice].x).getTime();

                                return m * (dateIndice - dateIndiceInicio) + anterior;
                            }

                            function copy(src) {
                                return { ...src };
                            }

                            lojasMap.forEach((loja) => {
                                const data = [];
                                loja.data.forEach((item, idx, itens) => {
                                    if (item.y === null) {
                                        const model = copy(item);
                                        model.hadNoData = true;
                                        model.y = getPontoMedio(idx, itens);
                                        data.push(model);
                                    } else {
                                        data.push(copy(item));
                                    }
                                });

                                const hasNoData = data.filter((item) => {
                                    return item.hadNoData;
                                });
                                if (hasNoData) {
                                    lojasMap.push({
                                        borderColor: loja.borderColor,
                                        backgroundColor: loja.backgroundColor,
                                        borderDash: [3, 4],
                                        label: `hide${loja.label}`,
                                        loja: loja.label,
                                        data,
                                        pointStyle: getPointStyle,
                                        pointBackgroundColor: getPointColor,
                                        pointBorderWidth: getPointBorderWidth,
                                        pointBorderColor: getPointBorderColor,
                                        radius: getPointRadius,
                                        pointRadius: getPointRadius,
                                    });
                                }
                            });
                            const newArrayDate = {};
                            resultSet.data.forEach((item) => {
                                newArrayDate[moment(item['BiDesagregado.dataColeta']).format('YYYY-MM-DD')] = newArrayDate[moment(item['BiDesagregado.dataColeta']).format('YYYY-MM-DD')] ? newArrayDate[moment(item['BiDesagregado.dataColeta']).format('YYYY-MM-DD')] : moment(item['BiDesagregado.dataColeta']).format('YYYY-MM-DD');
                            });
                            const datesMap = Object.values(newArrayDate);

                            lojasMap.forEach((item) => {
                                item.data.sort((a, b) => {
                                    return new Date(b.x) - new Date(a.x);
                                });
                            });

                            this.setState({
                                dados: lojasMap,
                                label: datesMap,
                            });
                        } else {
                            this.setState({
                                dados: [],
                                label: [],
                            });
                        }

                        if (this.state.first) {
                            this.createChart();
                            this.setState({
                                first: false,
                            });
                        } else {
                            this.myChart.data.datasets = this.state.dados;
                            this.myChart.data.labels = this.state.label;
                            this.myChart.options.scales.yAxes[0].ticks.max = this.state.maxValue;
                            if (document.getElementById('chartjs-tooltip')) {
                                document.getElementById('chartjs-tooltip').style.opacity = 0;
                            }
                            this.myChart.update();
                            this.updateTooltip();
                        }
                    }),
            );
        }
    }

    componentDidMount() {
        this.searchLoja({
            query: '', rede: '', uf: '', cidade: '', size: this.state.pageSizeLoja,
        });
        this.searchRede({
            query: '', uf: '', cidade: '', size: this.state.pageSizeLoja,
        });
        this.searchUf({ query: '', rede: '', size: this.state.pageSizeLoja });
    }

    handleDownload = () => {
        let csvDownloadContent = 'Data;Loja Identificaor;Loja Descrição;Código;Descrição;Preço;Preço atacado;Gatilho atacado;Promoção;Preço de;Data de validade;Clube de desconto;Foto;Sugestão de expurgo\n';
        if (this.state.dadosBrutos.length) {
            csvDownloadContent += this.state.dadosBrutos.map((item) => {
                const line = [];
                line.push(
                    moment(item['BiDesagregado.dataColeta']).format('DD/MM/YYYY'),
                    item['BiDesagregado.lojaCliente'],
                    item['BiDesagregado.lojaClienteDescricao'],
                    item['BiDesagregado.gtinCliente'],
                    item['BiDesagregado.descricaoCliente'],
                    item['BiDesagregado.preco'] ? Number(item['BiDesagregado.preco']).toFixed(2) : null,
                    item['BiDesagregado.precoAtacado'] ? Number(item['BiDesagregado.precoAtacado']).toFixed(2) : null,
                    item['BiDesagregado.gatilhoAtacado'],
                    item['BiDesagregado.tipoPromocao'],
                    item['BiDesagregado.precoDe'] ? Number(item['BiDesagregado.precoDe']).toFixed(2) : null,
                    item['BiDesagregado.dataValidade'] ? moment(item['BiDesagregado.dataValidade']).format('DD/MM/YYYY') : null,
                    item['BiDesagregado.clubeDesconto'],
                    getLinkFoto(item['BiDesagregado.linkFoto']),
                    item['BiDesagregado.sugestaoExpurgoRaw'],
                );
                return line.join(';');
            }).join('\n');
            const csvData = new Blob([csvDownloadContent], { type: 'text/csv' });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = window.URL.createObjectURL(csvData);
            hiddenElement.charset = 'utf-8-sig';
            hiddenElement.target = '_blank';
            hiddenElement.download = 'historico_preco.csv';
            hiddenElement.click();
        }
    };

    applyFilter = () => {
        this.getData();
    };

    validProdutoInput() {
        const error = this.state.errors;
        error.produto = null;
        this.setState({ errors: error });
        const produto = document.getElementsByName('produto')[0];
        if (produto) {
            produto.classList.remove('invalid-input');
        }
    }

    handleSelectProduto = (selectedItem) => {
        if (selectedItem) {
            this.validProdutoInput();
            const produto = [];
            produto.push(selectedItem.identificadorCliente);
            this.setState({
                filtroProduto: produto,
            });
        }
    };

    handleFilterProduto = (inputValue, e) => {
        if (inputValue) {
            ProdutoService.getProdutoCliente({ query: inputValue }).then((data) => {
                if (data.length) {
                    const responseProduto = data.map((item) => {
                        return {
                            id: `${item.codigoProduto}-${item.identificadorCliente}`,
                            value: `${item.identificadorCliente} - ${item.descricaoCliente}`,
                            label: `${item.identificadorCliente} - ${item.descricaoCliente}`,
                            children: data,
                            codigo: item.codigoProduto,
                            identificadorCliente: item.identificadorCliente,
                        };
                    });
                    this.setState({
                        produtos: responseProduto,
                    });
                }
            });
        } else {
            this.setState({
                produtos: [],
            });
        }
    };

    validDateInput() {
        const error = this.state.errors;
        error.data = null;
        this.setState({ errors: error });
        const data = document.getElementsByName('data')[0];
        if (data) {
            data.classList.remove('invalid-input');
        }
    }

    handleFilterData = (value) => {
        if (value) {
            this.validDateInput();
        }

        const datas = [];
        value.forEach((item) => {
            datas.push(moment(item).format('YYYY-MM-DD'));
        });

        this.setState({
            data: datas,
        });
    };

    handleChange = (a, event) => {
        this.setState({ value: a });
        if (a === null) {
            this.setState({
                cacheData: [],
                filtroLojas: [],
                showErroLoja: false,
            });
        }
        if (a !== null && a.length !== this.state.cacheData.length) {
            const newcacheDataca = this.state.cacheData.filter((item) => {
                return a.indexOf(item.label) !== -1;
            });
            this.setState({
                cacheData: newcacheDataca,
                filtroLojas: newcacheDataca.map((item) => { return item.identificador; }),
                showErroLoja: newcacheDataca.length > 20,
            });
        }
    };

    searchLoja(filtros) {
        LojaService.getLojasCliente({
            query: filtros.query, rede: filtros.rede, uf: filtros.uf, cidade: filtros.cidade, size: this.state.pageSizeLoja,
        }).then((data) => {
            if (data) {
                this.setState({
                    lojas: data.map((item) => {
                        return {
                            label: `${item.identificador} - ${item.descricao}`,
                            value: `${item.identificador} - ${item.descricao}`,
                            descricao: item.descricao,
                            identificador: item.identificador,
                            codigo: item.codigo,
                        };
                    }).sort((a, b) => {
                        if (a.identificador > b.identificador) {
                            return 1;
                        }
                        if (a.identificador < b.identificador) {
                            return -1;
                        }
                        return 0;
                    }),
                    indeterminateLojas: false,
                    checkAllLojas: false,
                });
            }
        });
    }

    dispatchFilterData(name, data) {
        this.props.updateFilterDataHistorico(name, data);
    }

    searchRede(filtros) {
        LojaService.getRedes({
            query: filtros.query, cidade: filtros.cidade, uf: filtros.uf, size: filtros.size,
        }).then((data) => {
            if (data && data.length) {
                const redes = data.map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                });

                this.dispatchFilterData('rede', redes);
            }
        });
    }

    searchUf(filtros) {
        LojaService.getUf({ query: filtros.query, rede: filtros.rede, size: filtros.size }).then((data) => {
            if (data && data.length) {
                const ufs = data.map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                });

                this.dispatchFilterData('uf', ufs);
            }
        });
    }

    searchCidade(filtros) {
        LojaService.getCidade({
            query: filtros.query, rede: filtros.rede, uf: filtros.uf, size: filtros.size,
        }).then((data) => {
            if (data && data.length) {
                const cidades = data.map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                });

                this.dispatchFilterData('cidade', cidades);
            }
        });
    }

    handleFilterLoja = (inputValue, e) => {
        this.setState({
            checkAllLojas: false,
        });

        const filtros = {
            query: typeof inputValue === 'string' ? inputValue : '',
            rede: this.state.filtroRede || '',
            uf: this.state.filtroUf || '',
            cidade: this.state.filtroCidade || '',
        };

        this.searchLoja(filtros);
    };

    validLojasInput() {
        const error = this.state.errors;
        error.lojas = null;
        this.setState({ errors: error });
        const lojas = document.getElementsByClassName('rs-picker-input rs-picker-toggle-wrapper rs-picker-placement-bottom-start rs-picker-block rs-picker-tag')[0];
        if (lojas) {
            lojas.classList.remove('invalid-input');
        }
    }

    handleSelect = (value, item, event) => {
        if (value) {
            this.validLojasInput();
        }

        const { cacheData } = this.state;
        cacheData.push(item);
        this.setState({
            cacheData,
            filtroLojas: cacheData.map((item) => { return item.identificador; }),
            showErroLoja: cacheData.length > 20,
        });
    };

    handleSelectMoreFilter(name, value) {
        this.props.updateFilterValue(name, value);

        if (name !== 'cidade') {
            this.searchCidade({
                query: '',
                uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
                rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
                size: this.state.pageSizeLoja,
            });
        }
        if (name !== 'uf' && name !== 'cidade') {
            this.searchUf({
                query: '',
                rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
                size: this.state.pageSizeLoja,
            });
        }

        if (name !== 'rede') {
            this.searchRede({
                query: '',
                uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
                cidade: name === 'cidade' ? value : value === '' ? '' : this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : '',
                size: this.state.pageSizeLoja,
            });
        }

        this.searchLoja({
            query: '',
            rede: name === 'rede' ? value : this.props.filtersData.rede?.value?.length ? this.props.filtersData.rede?.value : '',
            cidade: name === 'cidade' ? value : value === '' ? '' : this.props.filtersData.cidade?.value?.length ? this.props.filtersData.cidade?.value : '',
            uf: name === 'uf' ? value : this.props.filtersData.uf?.value?.length ? this.props.filtersData.uf?.value : '',
            size: this.state.pageSizeLoja,
        });
    }

    handleExpurgo = (value, checked) => {
        const sugestaoExpurgo = !!checked;

        this.setState({
            expurgo: sugestaoExpurgo,
        });
    };

    handleCheckAllLojas(value, checked) {
        let newCacheData = this.state.cacheData;

        if (checked) {
            this.state.lojas.forEach((item) => {
                if (newCacheData.findIndex((cache) => { return item.label === cache.label; }) === -1) {
                    newCacheData.push(item);
                }
            });
        } else {
            newCacheData = newCacheData.filter((cacheItem) => {
                return this.state.lojas.findIndex((item) => { return cacheItem.label === item.label; }) === -1;
            });
        }

        this.setState({
            value: newCacheData.map((item) => item.value),
            indeterminateLojas: false,
            checkAllLojas: checked,
            filtroLojas: newCacheData.map((item) => item.identificador),
            cacheData: newCacheData,
            showErroLoja: newCacheData.length > 20,
        });
    }

    render() {
        const maxDate = moment().toDate();
        const minDate = moment().subtract(92, 'days').toDate();

        return (

            <Container style={{ width: '95vw' }} className="main-container historico-preco-page">
                <div id="container">
                    <Content className="filtros">
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={18} md={16} xs={24}>
                                <h1>Histórico de preço</h1>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} style={{ marginTop: '7px' }} lg={5} md={7} sm={23} xs={23}>
                                <FormGroup>
                                    <DataRangePicker
                                        id="date-filter"
                                        className={this.state.classDateInputInvalid}
                                        gridSize={24}
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        name="data"
                                        placeholder="Data*"
                                        onChangeValue={this.handleFilterData}
                                        ranges={[
                                            {
                                                label: 'Últimos 30 dias',
                                                value: [moment().subtract(31, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                            },
                                            {
                                                label: 'Últimos 60 dias',
                                                value: [moment().subtract(61, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                            },
                                            {
                                                label: 'Últimos 90 dias',
                                                value: [moment().subtract(91, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                                            },
                                        ]}
                                    />
                                    <HelpBlock className="invalid-field" name="erroData">{this.state.errors.data}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={1} xs={1}>
                                <Whisper placement="right" trigger="hover" speaker={<Tooltip>Data da realização da coleta</Tooltip>}>
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={8} md={6} sm={23} xs={23}>
                                <FormGroup>
                                    <Autocomplete2 id="product-filter" items={this.state.produtos} onSelect={this.handleSelectProduto} name="produto" placeholder="Produto*" onInputValueChange={this.handleFilterProduto} itemToString={(item) => (item ? item.value : '')} gridSize={24} />
                                    <HelpBlock className="invalid-field" name="erroProduto">{this.state.errors.produto}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={1} md={1} sm={1} xs={1}>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <p>Filtro de produto</p>
                                            <p>Pesquise o produto que deseja ver o histórico de preços.</p>
                                        </Tooltip>
                                    }
                                >
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={5} md={4} sm={12} xs={12}>
                                <Toggle style={{ marginRight: '10px' }} defaultChecked onChange={this.handleExpurgo} value={this.state.expurgo} />
                                <span style={{ fontSize: '13px' }}>Exibir dados fora do range</span>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} lg={3} md={4} sm={24} xs={24} lgPush={1} mdPush={1}>
                                <Button id="btn-aplicar" appearance="primary" type="submit" justify="end" block onClick={this.applyFilter}>Aplicar</Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} lg={14} md={14} sm={22} xs={22} className="mg-bottom-0">
                                <FormGroup>
                                    <CheckPicker
                                        block
                                        className="inf-picker"
                                        id="store-filter"
                                        filter={false}
                                        value={this.state.value}
                                        data={this.state.lojas}
                                        locale={{
                                            noResultsText: 'Nenhum item encontrado',
                                            searchPlaceholder: 'Busque a loja desejada',
                                        }}
                                        name="loja"
                                        placeholder="Concorrentes*"
                                        onSelect={this.handleSelect}
                                        onSearch={(search) => this.handleFilterLoja(search)}
                                        onChange={this.handleChange}
                                        onClean={this.handleFilterLoja}
                                        maxHeight={200}
                                        renderMenuItem={(label, item) => {
                                            return (
                                                <div>
                                                    <span>{label}</span>
                                                </div>
                                            );
                                        }}
                                        renderExtraFooter={() => (
                                            <div style={footerStyles}>
                                                <Checkbox
                                                    inline
                                                    indeterminate={this.state.indeterminateLojas}
                                                    checked={this.state.checkAllLojas}
                                                    onChange={this.handleCheckAllLojas}
                                                >
                                                    Selecionar tudo
                                                    {' '}
                                                    <span>{this.state.lojas && this.state.lojas.length ? `(${this.state.lojas.length})` : null}</span>
                                                </Checkbox>
                                            </div>
                                        )}
                                    />
                                    <HelpBlock className="invalid-field" name="erroLoja">{this.state.errors.lojas}</HelpBlock>
                                </FormGroup>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} colspan={1} lg={1} md={1} sm={2} xs={2}>
                                <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <p>Filtro de Loja</p>
                                            <p>Pesquise as lojas que deseja ver o histórico de preços.</p>
                                        </Tooltip>
                                    }
                                >
                                    <Icon className="tooltip-info" icon="info" />
                                </Whisper>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} colspan={1} lg={1} md={1} sm={2} xs={2}>

                                <Whisper
                                    trigger="click"
                                    placement="bottomStart"
                                    speaker={
                                        <MoreFilterDropdown
                                            className="popover-filtro-dinamico"
                                            filtersType={this.props.filtersType}
                                            selectedFilters={this.props.selectedFilters}
                                            filters={this.props.filters}
                                            addFilter={this.props.addFiltroHistorico}

                                        />
                                    }
                                >
                                    <ButtonToolbar>
                                        <IconButton id="add-filter" icon={<Icon icon="plus" />}>Adicionar filtros</IconButton>
                                    </ButtonToolbar>
                                </Whisper>
                            </FlexboxGrid.Item>

                        </FlexboxGrid>
                        {this.state.showErroLoja
                            ? <FlexboxGrid>
                                <FlexboxGrid.Item componentClass={Col} colspan={2} lg={4} mg={4} sm={24} xs={24}>
                                    <span className="warning-limit-lojas">Recomendamos a seleção de apenas 20 lojas por vez</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid> : null}
                        <FlexboxGrid>
                            {this.props.selectedFilters.length ? this.props.selectedFilters.map((filtro, index) => (
                                <InputFilter
                                    key={index}
                                    index={index}
                                    filter={filtro}
                                    data={this.props.filtersData}
                                    handleDeleteFiltro={this.props.deleteFiltroHistorico}
                                    selectValue={this.handleSelectMoreFilter}
                                />
                            )) : ''}
                        </FlexboxGrid>
                    </Content>

                </div>
                <LoadingSpiner size="md" />

                <Content>
                    <FlexboxGrid justify="end">
                        <FlexboxGrid.Item componentClass={Col} lg={3} md={3} sm={4} xs={4}>
                            <Dropdown
                                style={{ float: 'right' }}
                                id="download-btn"
                                placement="bottomEnd"
                                title="Download"
                                renderTitle={() => {
                                    return <Button block appearance="primary">
                                        <Icon icon="cloud-download" />
                                        {' '}
                                        Download
                                        {' '}
                                    </Button>;
                                }}
                            >
                                <Dropdown.Item id="download-csv-file" eventKey="csv" onSelect={this.handleDownload} icon={<Icon icon="file-text-o" />}>.csv</Dropdown.Item>
                            </Dropdown>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item componentClass={Col} colspan={24} lg={24} mg={24} sm={24} xs={24}>
                            <canvas
                                id="myChart"
                                ref={this.chartRef}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>

        );
    }
}

const mapStateToProps = (store) => ({
    codigoCliente: store.clienteDataReducer.codigoCliente,
    selectedFilters: store.historicoPrecoDataReducer.selectedFilters,
    filters: store.historicoPrecoDataReducer.filters,
    filtersType: store.historicoPrecoDataReducer.filtersType,
    filtersData: store.historicoPrecoDataReducer.filtersData,
});

export default withRouter(connect(mapStateToProps, {
    addFiltroHistorico, deleteFiltroHistorico, updateFilterDataHistorico, updateFilterValue,
})(HistoricoPrecoPage));

const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5',
};
