import Immutable from 'seamless-immutable';
import {
    ADD_FILTRO_PANEL,
    CHANGE_ADD_PRODUCT_PANEL,
    CHANGE_CACHE_PANEL,
    CHANGE_CHECK_ALL_PRODUCT,
    CHANGE_NO_CACHE_VALUE,
    CHANGE_REMOVE_PRODUCT_PANEL,
    CHANGE_VALUE_PANEL,
    CLEAR_FILTROS,
    CLEAR_FILTROS_DINAMICOS,
    CLEAR_FILTROS_PANEL,
    CLEAR_TOTALDATAPOINT,
    DELETE_FILTRO_ENDERECO,
    DELETE_FILTRO_PANEL,
    LIST_ENDERECO,
    LIST_ITENS_FILTROS_PANEL,
    LIST_PRODUTOS,
    SELECT_VALUE_PANEL,
    SET_AD,
    SET_CHANNEL,
    SET_CUSTOM_FILTER_LIST,
    SET_DATA,
    SET_DATA_BOXPLOT,
    SET_DATA_MAP,
    SET_DATA_TIME_EVOLUTION,
    SET_ERRO_PAINEL_GERAL,
    SET_FILTROS_SALVOS,
    SET_FILTROS_SALVOS_PANEL,
    SET_LAT_LON,
    SET_LIMIT_PRODUCTS,
    SET_LIMIT_PRODUCTS_FILTROS_DATA,
    SET_LOADING_STATUS,
    SET_MARKERS_DATA,
    SET_PERIODO_TIME_EVOLUTION,
    SET_PRICE_FILTER,
    SET_PRODUCT_DASHBOARD_LIST,
    SET_SHARE_TOKENS_DATA,
    SET_SINGLE_REDUCER,
    SET_SORT_COLUMN_BOXPLOT,
    SET_TOP_FIVE_DATA,
    SET_TOTAL_DATAPOINTS,
    SET_USERS_EMAIL_DATA,
    SET_VISUALIZATION_TYPE_BOXPLOT,
    SET_VISUALIZATION_TYPE_MAP,
    SET_VISUALIZATION_TYPE_TIME_EVOLUTION,
} from '../actions/actionsPainelGeral';

const painelGeralInitialState = Immutable({
    tiposFiltro: [
        {
            icon: 'shopping-cart',
            title: 'LOJA',
            itens: [
                {
                    name: 'rede',
                    title: 'Rede',
                },
                {
                    name: 'tipoLoja',
                    title: 'Tipo de loja',
                },
            ],
        },
        {
            icon: 'shopping-basket',
            title: 'PRODUTO',
            itens: [
                {
                    name: 'fabricante',
                    title: 'Fabricante',
                },
                {
                    name: 'tipoProduto',
                    title: 'Tipo de produto',
                },
                {
                    name: 'secao',
                    title: 'Seção',
                },
                {
                    name: 'categoria',
                    title: 'Categoria',
                },
            ],
        },
        {
            icon: 'crown',
            title: 'ANÚNCIOS',
            itens: [
                {
                    name: 'mecanica',
                    title: 'Mecânica',
                },
                {
                    name: 'grupoMidia',
                    title: 'Grupo de mídia',
                },
                {
                    name: 'midia',
                    title: 'Mídia',
                },
            ],
        },
        {
            icon: 'map-marker',
            title: 'LOCALIZAÇÃO',
            itens: [
                {
                    name: 'uf',
                    title: 'UF',
                },
                {
                    name: 'cidade',
                    title: 'Cidade',
                },
                {
                    name: 'endereco',
                    title: 'Endereço',
                },
            ],
        },
        {
            icon: 'tag',
            title: 'PREÇO',
            itens: [
                {
                    name: 'tipoPreco',
                    title: 'Tipo de preço',
                },
                {
                    name: 'origemPreco',
                    title: 'Origem do preço',
                },
            ],
        },
    ],
    filtros: [
        {
            name: 'tipoPreco',
            cleanable: true,
            searchable: false,
            title: 'Tipo de preço',
            placeholder: 'Selecione um tipo de preço',
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'origemPreco',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Origem do preço',
            placeholder: 'Selecione uma origem',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder:
                    'Digite ou busque a origem da preço desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'tipoProduto',
            cleanable: true,
            searchable: false,
            title: 'Tipo de produto',
            placeholder: 'Selecione um tipo de produto',
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
            tooltipMessage:
                'Alguns exemplos de produtos sem código de barras são frutas e legumes que são comercializados por peso (a granel).',
        },
        {
            name: 'fabricante',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Fabricante',
            placeholder: 'Selecione um fabricante',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder:
                    'Digite ou busque o fabricante de produtos desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'categoria',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Categoria',
            placeholder: 'Selecione uma categoria',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder:
                    'Digite ou busque a categoria de produtos desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'secao',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Seção',
            placeholder: 'Selecione uma seção',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder:
                    'Digite ou busque a seção de produtos desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'rede',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Rede',
            placeholder: 'Selecione uma rede',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque a rede desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'tipoLoja',
            cleanable: true,
            filter: true,
            searchable: true,
            title: 'Tipo de loja',
            placeholder: 'Selecione um tipo de loja',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o tipo de loja desejado',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'uf',
            cleanable: true,
            filter: true,
            searchable: true,
            title: 'UF',
            placeholder: 'Selecione uma UF',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque a UF desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'cidade',
            filter: false,
            title: 'Cidade',
            placeholder: 'Selecione uma cidade',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque a cidade desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'endereco',
            cleanable: true,
            filter: false,
            searchable: true,
            title: 'Endereço',
            placeholder: 'Digite um endereço',
            locale: { noResultsText: 'Pesquise o endereço desejado' },
            onSelect: 'handleSelect',
            onSearch: 'searchEndereco',
            onClean: 'handleSelect',
            tipoCampo: 2,
        },
        {
            name: 'mecanica',
            filter: false,
            title: 'Mecânica',
            placeholder: 'Selecione uma mecânica',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque a mecânica desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'grupoMidia',
            filter: false,
            title: 'Grupo de mídia',
            placeholder: 'Selecione um grupo de mídia',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque o grupo de mídia desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
        {
            name: 'midia',
            filter: false,
            title: 'Mídia',
            placeholder: 'Selecione uma mídia',
            locale: {
                noResultsText: 'Nenhum item encontrado',
                searchPlaceholder: 'Digite ou busque a mídia desejada',
            },
            onSelect: 'handleSelect',
            onSearch: 'getItensFiltro',
            onChange: 'handleChange',
            tipoCampo: 1,
        },
    ],
    filtrosAdicionados: [],
    filtrosData: {
        preco: {
            name: '',
        },
        tipoPreco: {
            lista: [],
            value: [],
            cache: [],
        },
        origemPreco: {
            lista: [],
            value: [],
            cache: [],
        },
        fabricante: {
            lista: [],
            value: [],
            cache: [],
        },
        tipoProduto: {
            lista: [],
            value: [],
            cache: [],
        },
        categoria: {
            lista: [],
            value: [],
            cache: [],
        },
        secao: {
            lista: [],
            value: [],
            cache: [],
        },
        rede: {
            lista: [],
            value: [],
            cache: [],
            erro: null,
        },
        tipoLoja: {
            lista: [],
            value: [],
            cache: [],
        },
        uf: {
            lista: [],
            value: [],
            cache: [],
        },
        cidade: {
            lista: [],
            value: [],
            cache: [],
        },
        mecanica: {
            lista: [],
            value: [],
            cache: [],
        },
        grupoMidia: {
            lista: [],
            value: [],
            cache: [],
        },
        midia: {
            lista: [],
            value: [],
            cache: [],
        },
        produto: {
            lista: [],
            value: [],
            cache: [],
            limitProducts: null,
        },
        dataInicio: null,
        dataFim: null,
        endereco: {
            lista: [],
            value: '',
            token: null,
            latitude: null,
            longitude: null,
            erro: null,
        },
        raio: {
            lista: [
                {
                    label: '2 KM',
                    value: 2,
                },
                {
                    label: '3 KM',
                    value: 3,
                },
                {
                    label: '4 KM',
                    value: 4,
                },
                {
                    label: '5 KM',
                    value: 5,
                },
                {
                    label: '6 KM',
                    value: 6,
                },
                {
                    label: '7 KM',
                    value: 7,
                },
                {
                    label: '8 KM',
                    value: 8,
                },
                {
                    label: '9 KM',
                    value: 9,
                },
                {
                    label: '10 KM',
                    value: 10,
                },
            ],
            value: null,
        },
        canal: ['Canal físico', 'Canal digital'],
        ad: false,
    },
    canal: ['Canal físico', 'Canal digital'],
    ad: false,
    raio: {
        lista: [
            {
                label: '2 KM',
                value: 2,
            },
            {
                label: '3 KM',
                value: 3,
            },
            {
                label: '4 KM',
                value: 4,
            },
            {
                label: '5 KM',
                value: 5,
            },
            {
                label: '6 KM',
                value: 6,
            },
            {
                label: '7 KM',
                value: 7,
            },
            {
                label: '8 KM',
                value: 8,
            },
            {
                label: '9 KM',
                value: 9,
            },
            {
                label: '10 KM',
                value: 10,
            },
        ],
        value: null,
    },
    endereco: {
        lista: [],
        value: '',
        token: null,
        latitude: null,
        longitude: null,
        erro: null,
    },
    dataInicio: null,
    dataFim: null,
    produto: {
        lista: [],
        value: [],
        cache: [],
        limitProducts: null,
    },
    rede: {
        lista: [],
        value: [],
        cache: [],
        erro: null,
    },
    preco: {
        name: '',
    },
    tipoPreco: {
        lista: [],
        value: [],
        cache: [],
    },
    origemPreco: {
        lista: [],
        value: [],
        cache: [],
    },
    fabricante: {
        lista: [],
        value: [],
        cache: [],
    },
    tipoProduto: {
        lista: [],
        value: [],
        cache: [],
    },
    categoria: {
        lista: [],
        value: [],
        cache: [],
    },
    secao: {
        lista: [],
        value: [],
        cache: [],
    },
    tipoLoja: {
        lista: [],
        value: [],
        cache: [],
    },
    uf: {
        lista: [],
        value: [],
        cache: [],
    },
    cidade: {
        lista: [],
        value: [],
        cache: [],
    },
    mecanica: {
        lista: [],
        value: [],
        cache: [],
    },
    grupoMidia: {
        lista: [],
        value: [],
        cache: [],
    },
    midia: {
        lista: [],
        value: [],
        cache: [],
    },
    boxplot: {
        data: null,
        type: 'produto',
        sortColumn: null,
    },
    timeEvolution: {
        data: null,
        type: 'produto',
        periodo: 'SEMANA',
    },
    map: {
        data: null,
        type: 'estadual',
    },
    topFive: {
        data: {},
    },
    totalDatapoints: null,
    loadingBoxplot: true,
    loadingTimeEvolution: true,
    loadingMap: false,
    loadingTopFive: true,
    loadingProductDashboard: true,
    loadingProductDashboardTable: true,
    productDashboardList: [],
    productDashboardTableList: [],
});

function painelGeralDataReducer(state = painelGeralInitialState, action) {
    switch (action.type) {
        case ADD_FILTRO_PANEL:
            return state.merge({
                filtrosAdicionados: [
                    ...state.filtrosAdicionados,
                    action.filtro,
                ],
            });
        case DELETE_FILTRO_PANEL:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                [action.name]: {
                    ...state[action.name],
                    value: [],
                    disabled: [],
                    cache: [],
                },
            });
        case DELETE_FILTRO_ENDERECO:
            return state.merge({
                filtrosAdicionados: state.filtrosAdicionados.filter(
                    (item, index) => index !== action.index,
                ),
                endereco: {
                    ...state.endereco,
                    value: [],
                    disabled: [],
                    erro: null,
                    latitude: null,
                    longitude: null,
                },
                raio: {
                    ...state.raio,
                    value: null,
                },
            });
        case CHANGE_VALUE_PANEL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        value: action.value,
                    },
                },
            });
        case CHANGE_CACHE_PANEL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: action.cache,
                    },
                },
            });
        case SELECT_VALUE_PANEL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        cache: [
                            ...state.filtrosData[`${action.name}`].cache,
                            action.item,
                        ],
                    },
                },
            });

        case CHANGE_ADD_PRODUCT_PANEL:
            return state.merge({
                ...state,
                produto: {
                    ...state.produto,
                    value: [...state.produto.value, action.item.value],
                    cache: [...state.produto.cache, action.item],
                },
            });
        case CHANGE_REMOVE_PRODUCT_PANEL:
            return state.merge({
                ...state,
                produto: {
                    ...state.produto,
                    value: state.produto.value.filter(
                        (i) => i !== action.value,
                    ),
                    cache: state.produto.cache.filter(
                        (i) => i.value !== action.value,
                    ),
                },
            });
        case CHANGE_CHECK_ALL_PRODUCT:
            return state.merge({
                ...state,
                produto: {
                    ...state.produto,
                    value: action.value,
                    cache: action.item,
                },
            });
        case LIST_ITENS_FILTROS_PANEL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        lista: state.filtrosData[action.name]?.value.length
                            ? state.filtrosData[action.name]?.cache.concat(
                                action.itens.filter(
                                    (i) => !state.filtrosData[
                                        action.name
                                    ]?.value.includes(i.value),
                                ),
                            )
                            : action.itens,
                    },
                },
            });

        case LIST_PRODUTOS:
            return state.merge({
                ...state,
                produto: {
                    ...state.produto,
                    lista: state.produto.cache
                        .filter(
                            (item) => action.list.findIndex(
                                (itemLista) => itemLista.id === item.value,
                            ) === -1,
                        )
                        .concat(
                            action.list.map((item) => ({
                                id: item.id,
                                value: item.id,
                                label: `${item.identificador} - ${item.descricao}`,
                                identificador: item.identificador,
                                descricao: item.descricao,
                                secao: item.secao,
                                categoria: item.categoria,
                                datapoints: item.datapoints,
                                limited: item.limited,
                                foto: item.foto,
                            })),
                        ),
                    value: action.identificadores
                        ? state.produto.cache
                            .concat(
                                action.list.filter(
                                    (cache) => state.produto.cache.findIndex(
                                        (item) => item.value === cache.id,
                                    ) === -1,
                                ),
                            )
                            .map((item) => item.id)
                        : state.produto.value,
                    cache: action.identificadores
                        ? state.produto.cache.concat(
                            action.list
                                .filter(
                                    (cache) => state.produto.cache.findIndex(
                                        (item) => item.value === cache.id,
                                    ) === -1,
                                )
                                .map((item) => ({
                                    id: item.id,
                                    value: item.id,
                                    label: `${item.identificador} - ${item.descricao}`,
                                    identificador: item.identificador,
                                    descricao: item.descricao,
                                    secao: item.secao,
                                    categoria: item.categoria,
                                    datapoints: item.datapoints,
                                    limited: item.limited,
                                    foto: item.foto,
                                })),
                        )
                        : state.produto.cache,
                },
            });
        case LIST_ENDERECO:
            return state.merge({
                ...state,
                endereco: {
                    ...state.endereco,
                    lista: action.list.predictions.map((item) => ({
                        label: item.description,
                        value: item.description,
                        place_id: item.place_id,
                    })),
                    token: action.list.session_token,
                },
            });
        case SET_LAT_LON:
            return state.merge({
                ...state,
                endereco: {
                    ...state.endereco,
                    latitude: action.data.geometry.location.lat,
                    longitude: action.data.geometry.location.lng,
                },
            });
        case SET_LIMIT_PRODUCTS:
            return state.merge({
                ...state,
                produto: {
                    ...state.produto,
                    limitProducts: action.value,
                },
            });
        case SET_DATA:
            return state.merge({
                ...state,
                dataInicio: action?.data[0] || null,
                dataFim: action?.data[1] || null,
            });
        case SET_ERRO_PAINEL_GERAL:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [action.name]: {
                        ...state.filtrosData[action.name],
                        erro: action.message,
                    },
                },
            });
        case SET_FILTROS_SALVOS:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    [`${action.name}`]: {
                        ...state.filtrosData[`${action.name}`],
                        lista:
                            state.filtrosData[
                                `${action.name}`
                            ]?.lista?.findIndex(
                                (item) => item.value === action.value,
                            ) === -1
                                ? [
                                    ...state.filtrosData[`${action.name}`]
                                        .lista,
                                    {
                                        label: action.value,
                                        value: action.value,
                                    },
                                ]
                                : state.filtrosData[`${action.name}`]?.lista,
                    },
                },
            });
        case SET_FILTROS_SALVOS_PANEL:
            return state.merge({
                ...state,
                [action.name]: {
                    ...state[action.name],
                    lista:
                        state[action.name]?.lista?.findIndex(
                            (item) => item.value === action.value,
                        ) === -1
                            ? [
                                ...state[action.name].lista,
                                {
                                    label: action.value,
                                    value: action.value,
                                },
                            ]
                            : state[action.name]?.lista,
                    cache:
                        state[action.name]?.lista?.findIndex(
                            (item) => item.value === action.value,
                        ) === -1
                            ? [
                                ...state[action.name].cache,
                                {
                                    label: action.value,
                                    value: action.value,
                                },
                            ]
                            : state[action.name]?.cache,
                },
            });
        case CLEAR_FILTROS:
            return state.merge({
                ...state,
                tiposFiltro: painelGeralInitialState.tiposFiltro,
                filtrosData: {
                    ...painelGeralInitialState.filtrosData,
                    dataInicio: state.filtrosData.dataInicio,
                    dataFim: state.filtrosData.dataFim,
                },
                filtrosAdicionados: painelGeralInitialState.filtrosAdicionados,
            });
        case CLEAR_FILTROS_PANEL:
            const filters = action?.list?.reduce((acc, elem) => {
                acc[elem] = painelGeralInitialState[elem];
                return acc;
            }, {});
            return state.merge({
                ...state,
                ...filters,
                tiposFiltro: painelGeralInitialState.tiposFiltro,
                dataInicio: state.dataInicio,
                dataFim: state.dataFim,
                filtrosAdicionados: painelGeralInitialState.filtrosAdicionados,
            });
        case CLEAR_TOTALDATAPOINT:
            return state.merge({
                ...state,
                totalDatapoints: painelGeralInitialState.totalDatapoints,
            })
        case CLEAR_FILTROS_DINAMICOS:
            return state.merge({
                ...state,
                tiposFiltro: painelGeralInitialState.tiposFiltro,
                filtrosData: {
                    ...painelGeralInitialState.filtrosData,
                    // produto: state.filtrosData.produto,
                    dataInicio: state.filtrosData.dataInicio,
                    dataFim: state.filtrosData.dataFim,
                },
                produto: state.produto,
                filtrosAdicionados: painelGeralInitialState.filtrosAdicionados,
            });
        case SET_DATA_BOXPLOT:
            return state.merge({
                ...state,
                boxplot: {
                    ...state.boxplot,
                    data: action.data,
                },
                loadingBoxplot: false,
            });
        case SET_VISUALIZATION_TYPE_BOXPLOT:
            return state.merge({
                ...state,
                boxplot: {
                    ...state.boxplot,
                    type: action.visualizationType,
                },
            });
        case SET_SORT_COLUMN_BOXPLOT:
            return state.merge({
                ...state,
                boxplot: {
                    ...state.boxplot,
                    sortColumn: action.sortColumn,
                },
            });
        case SET_DATA_TIME_EVOLUTION:
            return state.merge({
                ...state,
                timeEvolution: {
                    ...state.timeEvolution,
                    data: action.data,
                },
                loadingTimeEvolution: false,
            });
        case SET_VISUALIZATION_TYPE_TIME_EVOLUTION:
            return state.merge({
                ...state,
                timeEvolution: {
                    ...state.timeEvolution,
                    type: action.visualizationType,
                },
            });
        case SET_PERIODO_TIME_EVOLUTION:
            return state.merge({
                ...state,
                timeEvolution: {
                    ...state.timeEvolution,
                    periodo: action.periodo,
                },
            });
        case SET_DATA_MAP:
            return state.merge({
                ...state,
                map: {
                    ...state.map,
                    data: action.data,
                },
                loadingMap: false,
            });
        case SET_MARKERS_DATA:
            return state.merge({
                ...state,
                map: {
                    ...state.map,
                    markers: action.data.content.map((item) => ({
                        lat: item.latitude,
                        lng: item.longitude,
                        rede: item.rede,
                        price: item.preco_medio,
                    })),
                },
                loadingMap: false,
            });
        case SET_VISUALIZATION_TYPE_MAP:
            return state.merge({
                ...state,
                map: {
                    ...state.map,
                    type: action.visualizationType,
                },
            });
        case SET_TOP_FIVE_DATA:
            return state.merge({
                ...state,
                topFive: {
                    ...state.topFive,
                    data: action.data,
                },
                loadingTopFive: false,
            });
        case SET_SHARE_TOKENS_DATA:
            return state.merge({
                ...state,
                shareTokens: {
                    ...state.shareTokens,
                    data: action.data,
                },
            });
        case SET_USERS_EMAIL_DATA:
            return state.merge({
                ...state,
                userEmails: {
                    ...state.userEmails,
                    data: action.data,
                },
            });
        case SET_TOTAL_DATAPOINTS:
            return state.merge({
                ...state,
                totalDatapoints: action.data,
            });
        case SET_PRICE_FILTER:
            return state.merge({
                ...state,
                preco: {
                    name: action.data,
                },
            });
        case SET_CHANNEL:
            return state.merge({
                ...state,
                canal: action.data,
            });
        case SET_AD:
            return state.merge({
                ...state,
                ad: action.data,
            });
        case SET_LOADING_STATUS:
            return state.merge({
                ...state,
                [action.data.name]: action.data.status,
            });
        case SET_PRODUCT_DASHBOARD_LIST:
            return state.merge({
                ...state,
                [action.name]: action.data,
            });
        case SET_SINGLE_REDUCER:
            return state.merge({
                ...state,
                [action.name]: {
                    ...state[action.name],
                    value: action.value,
                    cache: action.item,
                },
            });
        case SET_CUSTOM_FILTER_LIST:
            return state.merge({
                ...state,
                [action.name]: {
                    ...state[action.name],
                    lista: state[action.name]?.cache
                        .filter(
                            (item) =>
                                action.data.findIndex(
                                    (itemLista) => itemLista.key === item.label,
                                ) === -1,
                        )
                        .concat(
                            action.data.map((item) => ({
                                label: item.key,
                                value: item.key,
                                datapoints: item.datapoints,
                            })),
                        ),
                },
            });
        case CHANGE_NO_CACHE_VALUE:
            return state.merge({
                ...state,
                [action.name]: {
                    ...state[action.name],
                    value: action.value,
                },
            });
        case SET_LIMIT_PRODUCTS_FILTROS_DATA:
            return state.merge({
                ...state,
                filtrosData: {
                    ...state.filtrosData,
                    produto: {
                        ...state.filtrosData.produto,
                        limitProducts: action.value,
                    },
                },
            });
        default:
            return state;
    }
}

export default painelGeralDataReducer;
